<template>
  <v-dialog
    v-model="localShowDialog"
    max-width="750"
    height="700"
  >
    <v-card class="dialog-card pb-2 overflow-unset">
      <div class="d-flex align-center justify-space-between mb-4">
        <h1 class="text-h5 primary--text">
          {{ $t('workflows.documents.copy_to_dataset_title') }}
        </h1>
        <div>
          <v-icon
            class="close-icon"
            size="16px"
            @click="closeDialog"
          >
            fas fa-times
          </v-icon>
        </div>
      </div>
      <div class="dialog-content">
        <CustomSelect
          v-model="localSelectedDataset"
          style="width: 400px;"
          placeholder="workflows.documents.select_dataset"
          :items="datasetOptions"
          :selected="selectedDatasetId"
          @selected-changed="onDatasetChange"
        />
        <v-text-field
          v-model="newEntryName"
          class="mt-4"
          variant="outlined"
          density="compact"
          style="width: 300px;"
          :placeholder="$t('workflows.documents.new_entry_name')"
        />
        <div class="d-flex justify-end">
          <v-btn
            class="clickable"
            color="primary"
            :disabled="!selectedDatasetId"
            @click="copyToDataset"
            rounded
          >
            {{ $t('workflows.documents.copy_to_dataset') }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

import CustomSelect from '@/components/common/elements/Forms/CustomSelect';
import { DatasetAPI } from '@/API/extract/DatasetAPI';

export default {
  name: 'CopyToDatasetDialog',

  components: {
    CustomSelect,
  },

  data() {
    return {
      localShowDialog: this.showDialog,
      datasetOptions: [],
      selectedDatasetId: null,
      newEntryName: null,
    };
  },

  computed: {
    isExternal() {
      return !!this.$route.params.token;
    },
  },

  watch: {
    showDialog(newVal) {
      this.localShowDialog = newVal;
    },
    localShowDialog(newVal) {
      this.$emit('update:showDialog', newVal);
    }
  },

  mounted() {
    this.fetchDatasets();
  },

  methods: {
    async fetchDatasets() {
      try {
        const response = await DatasetAPI.get(
          null,
          null,
          '',
          null,
          true,
          this.isExternal,
          this.isExternal,
          this.isExternal ? this.$route.params.token : null,
        );
        this.datasetOptions = response.data.map((dataset) => ({
          name: dataset.name,
          id: dataset.id,
        }));
      } catch (error) {
        console.error('Error fetching datasets:', error);
      }
    },

    onDatasetChange(datasetId) {
      this.selectedDatasetId = datasetId;
    },

    copyToDataset() {
      this.$emit('copyToDataset', { dataset_id: this.selectedDatasetId, entry_name: this.newEntryName });
      this.selectedDatasetId = null;
      this.newEntryName = null;
      this.localShowDialog = false;
    }
  },

  props: {
    showDialog: {
      type: Boolean,
      required: true
    }
  },

  emits: [
    'update:showDialog',
    'copyToDataset'
  ],
};
</script>

<style scoped>
.overflow-unset {
  overflow: visible !important;
}

.input-width {
  width: 400px;
}
</style>
