<template>
  <v-dialog
    v-model="openDialog"
    max-width="600"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
    @keydown.enter="validate()"
  >
    <v-card class="dialog-card">
      <v-icon
        style="position: absolute; right: 10px; top: 10px;"
        color="black"
        size="17"
        @click="$emit('close')"
      >
        fas fa-times
      </v-icon>
      <v-form ref="form">
        <h2 class="dialog-title mb-3">
          {{ $t('users.add.title') }}
        </h2>
        <div
          v-if="user.role === 'sysadmin'"
          class="mt-4"
        >
          <div
            class="label"
            style="margin-bottom: 5px"
          >
            {{ $t('forms.organization') }}
          </div>
          <OrgSelect
            class="bottom-gap"
            :selected="form.org_id"
            @selected-changed="(id) => {form.org_id = id}"
          />
        </div>
        <div class="label">
          {{ $t('forms.firstName') }}
        </div>
        <v-text-field
          v-model="form.first_name"
          style="margin-top: 5px"
          variant="outlined"
          color="primary"
          density="compact"
          :rules="commonFieldRules"
          :error-messages="errorsMessages.first_name"
        />
        <div class="label">
          {{ $t('forms.lastName') }}
        </div>
        <v-text-field
          v-model="form.last_name"
          style="margin-top: 5px"
          variant="outlined"
          color="primary"
          density="compact"
          :rules="commonFieldRules"
          :error-messages="errorsMessages.last_name"
        />
        <div class="label">
          {{ $t('forms.email') }}
        </div>
        <v-text-field
          v-model="form.email"
          style="margin-top: 5px"
          variant="outlined"
          color="primary"
          density="compact"
          :rules="emailFieldRules"
          :error-messages="errorsMessages.email"
        />
        <div v-if="roleChoices">
          <div class="label">
            {{ $t('role') }}
          </div>
          <v-radio-group
            v-model="form.role"
            style="margin-top: 5px"
            inline
          >
            <div
              v-for="item in roleChoices"
              :key="item.value"
              class="radio-box right-gap-sm"
            >
              <v-radio
                :label="item.label"
                :value="item.value"
              />
            </div>
          </v-radio-group>
        </div>
      </v-form>
      <div class="mt-8 d-flex">
        <div class="dialog-button mr-2">
          <v-btn
            style="box-shadow: none"
            variant="outlined"
            @click="cancelAddUser"
            block
            rounded
          >
            {{ $t('cancel') }}
          </v-btn>
        </div>
        <div class="dialog-button ml-2">
          <v-btn
            color="primary"
            @click="validate()"
            block
            rounded
          >
            {{ $t('save') }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import FormRules from '@/utils/classes/FormRules/FormRules';
import { UserAPI } from '@/API/authenticator/UserAPI';
import OrgSelect from "@/components/common/elements/Organizations/OrgSelect";

export default {
  name: 'CreateUserDialog',

  components: {
    OrgSelect,
  },

  data() {
    return ({
      commonFieldRules: [FormRules.required],
      emailFieldRules: [FormRules.emailLocal],
      roleChoices: null,
      valid: true,
      errorsMessages: {},
      openDialog: this.modelValue,
      form: {
        email: '',
        sysadmin: false,
        org_id: null,
        timezone: null,
        role: 'basic',
      },
    });
  },

  computed: {
    user() {
      if (this.$store.getters.loggedInUser) {
        return this.$store.getters.loggedInUser;
      }
      return null;
    },
  },

  watch: {
    openDialog(open) {
      this.$emit('update:modelValue', open);
    },

    modelValue(show) {
      this.openDialog = show;
    },
  },

  mounted() {
    this.form.org_id = this.user.org_id;
    this.form.timezone = this.user.timezone;
    const roleChoices = [
        { label: this.$t('roles.basic'), value: 'basic' },
        { label: this.$t('roles.orgadmin'), value: 'orgadmin' },
        { label: this.$t('roles.sysadmin'), value: 'sysadmin' }
      ];
    if (this.user.role == 'orgadmin'){
      this.roleChoices = roleChoices.slice(0, 2)
    }
    if (this.user.role == 'sysadmin'){
      this.roleChoices = roleChoices;
    }
  },

  methods: {

    cancelAddUser(){
      this.$refs.form.reset();
      this.$emit('close');
    },

    validate() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.createUser();
      }
    },

    async createUser() {
      try {
        this.$store.commit('setLoadingScreen', true);
        await UserAPI.createUser(this.form);
        await this.$store.commit('setSuccessMessage', this.$t('users.add.success'));
        this.$store.commit('setSuccessSnackbar', true);
        this.$emit('refresh');
        this.$emit('close');
        this.$refs.form.reset();
      } catch(error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    }
  },

  emits: ['close', 'refresh', 'update:modelValue'],
}
</script>

<style lang="scss" scoped>
.select-label {
  font-size: 0.875rem;
}

.dialog-button {
  width: 100%;
}
</style>
