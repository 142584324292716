
import { http } from '@/plugins/axios';

const BASE_PATH = 'auth/api/v1/';

export class OrgsAPI {
  static getOneOrg(id) {
    return http
      .get(`${BASE_PATH}organizations/${id}/`,
      { globalErrorHandlingOptOut: true })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getOrgs(
    limit, offset, nameHas = null, sortBy = 'id', sortDesc = true,
  ) {
    return http
      .get(`${BASE_PATH}organizations/`,
      {
        params: {
          limit,
          offset,
          name_has: nameHas,
          sort_by: sortBy,
          sort_desc: sortDesc,
        }
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static getPasswordPolicy(id) {
    return http
      .get(`${BASE_PATH}organizations/${id}/policy/`,
      { globalErrorHandlingOptOut: true })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static checkPassword(userId, password) {
    return http
      .post(`${BASE_PATH}organizations/${userId}/check_password/`,
      { password },
      { globalErrorHandlingOptOut: true })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static saveNewOrg(newOrg) {
    return http
      .post(`${BASE_PATH}organizations/`, newOrg)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static deleteOrg(id) {
    return http
      .delete(`${BASE_PATH}organizations/${id}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static editOrg(id, edited) {
    return http
      .put(`${BASE_PATH}organizations/${id}/login-settings/`, edited)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
