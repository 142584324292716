import { store } from '@/plugins/store';
import { ClassifyHealthAPI } from '@/API/classify/ClassifyHealthAPI';
import { AuthHealthAPI } from '@/API/authenticator/AuthHealthAPI';
import { ExtractHealthAPI } from '@/API/extract/ExtractHealthAPI';
import { SearchHealthAPI } from '@/API/search/SearchHealthAPI';
import { WorkflowHealthAPI } from '@/API/workflows/WorkflowHealthAPI';

class ProductHealthCheckClass {
  static get productServices() {
    return {
      suite: ['auth', 'extract', 'workflows', 'classify'],
      classify: ['classify'],
      search: ['auth', 'search']
    };
  }

  static get productMainService() {
    return {
      suite: 'extract',
      classify: 'classify',
      search: 'search',
    };
  }

  static get serviceHealthAPI() {
    return {
      auth: AuthHealthAPI,
      extract: ExtractHealthAPI,
      classify: ClassifyHealthAPI,
      search: SearchHealthAPI,
      workflows: WorkflowHealthAPI,
    };
  }


  static async check(product) {
    const services = this.productServices[product];
    const mainService = this.productMainService[product];
    let productRunning = true;

    for (let i = 0; i < services.length; i++) {
      const service = services[i];
      const status = store.getters.serviceStatus[service];
      if (!status.checked) {
        try {
          const serviceStatus = await this.serviceHealthAPI[service].get();
          store.commit('setServiceVersion', {service: service, version: serviceStatus.version});
          store.commit('setServiceStatus', {service: service, status: serviceStatus.status});
        } catch {
          store.commit('setServiceStatusUnreachable', service);
          if (service === mainService) {
            productRunning = false;
          }
        } finally {
          store.commit('setServiceStatusChecked', service);
        }
      } else if (service === mainService) {
        return status.running;
      }
    }

    return productRunning;
    }
  }

  export const ProductHealthCheck = ProductHealthCheckClass;

