<template>
  <v-dialog
    v-model="openDialog"
    max-width="400"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
    @keydown.enter="addPagetoDataset()"
  >
    <v-card class="dialog-card">
      <v-icon
        style="position: absolute; right: 10px; top: 10px;"
        color="black"
        size="17"
        @click="$emit('close')"
      >
        fas fa-times
      </v-icon>
      <v-form
        ref="form"
        @submit.prevent="addPagetoDataset()"
      >
        <h2 class="dialog-title mb-3">
          {{ $t('dataset.page.add') }}
        </h2>
        {{ $t('dataset.copy_page') }}
        <DatasetSelect
          class="top-gap-sm"
          :datasets="datasets"
          :selected="datasetId"
          @selected-changed="(id) => {datasetId = id}"
          @get-more="getDatasets(numberOfDatasets)"
        />
        <v-checkbox
          v-model="prefillAnnotations"
          color="primary"
          :label="$t('dataset.page.prefillAnnotations')"
        />
        <div
          v-if="warningMessage"
          class="warning-message-card top-gap"
        >
          <h4 class="label bottom-gap-sm">
            <v-icon
              class="mb-1"
              size="16"
            >
              fas fa-exclamation-triangle
            </v-icon>
            {{ $t('dataset.page.warning') }}
          </h4>
          <p align="justify">
            {{ warningMessage }}
          </p>
        </div>
        <div class="d-flex top-gap">
          <div class="dialog-button mr-2">
            <v-btn
              style="box-shadow: none"
              variant="outlined"
              @click="$emit('close')"
              block
              rounded
            >
              {{ $t('cancel') }}
            </v-btn>
          </div>
          <div class="dialog-button ml-2">
            <v-btn
              color="primary"
              type="submit"
              :disabled="datasetId === -1"
              block
              rounded
            >
              {{ $t('save') }}
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { http } from '@/plugins/axios';
import { DatasetAPI } from '@/API/extract/DatasetAPI';
import DatasetSelect from "@/components/extract/elements/Datasets/DatasetSelect";

export default {
  name: 'AddPageToDatasetDialog',

  components: {
    DatasetSelect,
  },

  data() {
    return {
      datasets: [],
      datasetId: -1,
      totalDatasets: 0,
      prefillAnnotations: false,
      openDialog: this.modelValue,
    };
  },

  computed: {
    numberOfDatasets() {
      return this.datasets.length;
    },

    external() {
      return !!this.$route.params.token;
    },

    selectedDataset() {
      return this.datasets.find(dataset => dataset.id === this.datasetId);
    },

    warningMessage() {
      const shareKeys = ["force_ocr", "custom_reading_order", "straighten_pages", "use_deskew"];
      if (this.selectedDataset && shareKeys.some(key => this.selectedDataset[key] !== this.documentType[key])) {
        return this.$t('dataset.page.warningMessage');
      }
      return '';
    }
  },

  watch: {
    openDialog(open) {
      this.$emit('update:modelValue', open);
    },

    modelValue(show) {
      this.openDialog = show;
    },
  },

  async mounted() {
    await this.getDatasets();
    this.datasetId = this.$store.getters.selectedDatasetId;
    if (this.datasetId <= 0 && this.numberOfDatasets > 0) {
      this.datasetId = this.datasets[0].id;
    }
  },

  methods: {
    async getDatasets(offset = 0, limit = 20) {
      if (offset && offset >= this.totalDatasets) {
        return;
      }
      try {
        this.$store.commit('setLoadingScreen', true);
        const response = await DatasetAPI.get(
          offset,
          limit,
          '',
          null,
          true,
          this.external,
          this.external,
          this.external ? this.$route.params.token : null
        );
        if (offset > 0) {
          this.datasets = [...this.datasets, ...response.data];
        } else {
          this.datasets = response.data;
        }
        this.totalDatasets = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit("setLoadingScreen", false);
      }
    },

    async addPagetoDataset() {
      try {
        this.$store.commit('setLoadingScreen', true);
        await http.post(
          'dataset/entry/file_representation/', null,  { params: {
            dataset_id: this.datasetId,
            file_id: this.document.id,
            page_nb: this.page,
            prefill_annotations: this.prefillAnnotations,
          }}
        );
        this.$store.commit('setSuccessMessage', this.$t('dataset.page.added'));
        this.$store.commit('setSuccessSnackbar', true);
        this.$emit('close');
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    }
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    document: {
      type: Object,
      required: true,
    },

    documentType: {
      type: Object,
      required: true,
    },

    page: {
      type: Number,
      required: true,
    }
  },

  emits: ['close', 'update:modelValue'],

}
</script>

<style lang="scss" scoped>
.dialog-button {
  width: 100%;
}
</style>
