<template>
  <v-dialog
    v-model="innerModel"
    width="980"
    height="620"
  >
    <v-card class="dialog-card">
      <div class="d-flex justify-space-between bottom-gap">
        <h2 class="dialog-title">
          {{ $t('workflows.steps_library') }}
        </h2>
        <div class="d-flex">
          <input
            v-model="nodeFilter"
            class="node-filter mt-0 mr-1 pa-2"
            :placeholder="$t('filter')"
          >
        </div>
      </div>
      <div style="position: relative">
        <div
          class="node-dialog-options"
          @scroll="getMoreExtractionAgents()"
        >
          <div
            v-for="(option, nodeIndex) in filteredNodeOptions"
            :key="`option.name${nodeIndex}`"
            class="inline-middle"
          >
            <v-tooltip
              v-if="!isDraft || (option.production || option.future)"
              bottom
            >
              <template #activator="{ props }">
                <NodeCard
                  v-bind="props"
                  :title="option.name"
                  :type="option.macroType || null"
                  :task-type="option.taskType || null"
                  :supports="option.supports || []"
                  :owner="option.owner || null"
                  disabled
                />
              </template>
              {{ option.production ? $t('workflows.nodes.production') : $t('workflows.nodes.future') }}
            </v-tooltip>

            <NodeCard
              v-else
              :title="option.name"
              :type="option.macroType || null"
              :task-type="option.taskType || null"
              :supports="option.supports || []"
              :owner="option.owner || null"
              @add-node="handleAddNode(option.value)"
            />
          </div>
        </div>
        <div class="node-dialog-sidebar">
          <div
            v-for="(category, categoryIndex) in nodeCategories"
            :key="categoryIndex"
            class="node-category clickable"
            :class="{ 'selected-category': selectedCategory === category }"
            @click="selectCategory(category)"
          >
            {{ $t(`workflows.steps.${category}`) }}
            <span v-if="categoryIndex === 0">
              ({{ nodeOptions.length + totalDisplayExtractionAgents }})
            </span>
            <span v-else>
              ({{ getCategoryOptionCount(category) }})
            </span>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { DocTypeAPI } from '@/API/extract/DocTypeAPI';

import NodeCard from '@/components/extract/elements/Workflows/NodeCard.vue';

export default {
  name: 'AddNodeDialog',

  components: { NodeCard },

  data() {
    return {
      innerModel: this.modelValue,
      nodeFilter: '',
      selectedCategory: 'all',
      extractionAgents: [],
      filteredExtractionAgents: [],
      totalExtractionAgents: 0,
      totalDisplayExtractionAgents: 0,
      gettingExtractionAgents: false,
      gotExtractionAgents: false,
      extractOptionName: this.$t('workflows.steps.extract'),
    };
  },

  computed: {
    nodeCategories() {
      const categories = [];
      this.nodeOptions.forEach(option => {
        if (option.macroType && !categories.includes(option.macroType)) {
          categories.push(option.macroType);
        }
        if (option.taskType && !categories.includes(option.taskType)) {
          categories.push(option.taskType);
        }
        if (option.supports) {
          option.supports.forEach(supported => {
            if (!categories.includes(supported)) {
              categories.push(supported);
            }
          });
        }
      });
      categories.sort((a, b) => {
        const textA = this.$t(`workflows.steps.${a}`).toUpperCase();
        const textB = this.$t(`workflows.steps.${b}`).toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      return ['all', ...categories]
    },

    orgName() {
      return this.$store.getters.loggedInUser.org_name;
    },

    trimmedNodeFilter() {
      return this.nodeFilter.trim().toLowerCase();
    },

    nodeOptionsWithDynamic() {
      const extractIndex = this.nodeOptions.findIndex(
        o => o.name === this.extractOptionName
      );
      if (extractIndex === -1) {
        return this.nodeOptions;
      }
      const startNodes = this.nodeOptions.slice(0, extractIndex + 1);
      const endNodes = this.nodeOptions.slice(extractIndex + 1);
      const allOptions = [
        ...startNodes,
        ...this.getDynamicExtractOptions(this.nodeOptions[extractIndex]),
        ...endNodes,
      ]
      allOptions.sort((a, b) => {
        const textA = (a.name || '').toUpperCase();
        const textB = (b.name || '').toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      return allOptions;
    },

    filteredNodeOptions() {
      return this.nodeOptionsWithDynamic.filter((n) => {
        if (this.selectedCategory === 'all') {
          return (n.name || '').toLowerCase().includes(this.trimmedNodeFilter);
        }
        if (
          n.macroType || n.taskType || (n.supports && n.supports.length > 0)
        ) {
          return (
            !n.name.endsWith('Loading') &&
            (
              n.macroType === this.selectedCategory ||
              n.taskType === this.selectedCategory ||
              n.supports && n.supports.includes(this.selectedCategory)
            ) &&
            n.name.toLowerCase().includes(this.trimmedNodeFilter)
          );
        }
        return false;
      });
    },
  },

  watch: {
    innerModel(newValue) {
      this.$emit('update:model-value', newValue);
    },

    modelValue(newValue) {
      this.innerModel = newValue;
    },

    trimmedNodeFilter: _.debounce(
      function() {
        let extractCategory = false;
        if (this.selectedCategory !== 'all') {
          const extractOption = this.nodeOptions.find(
            o => o.name === this.extractOptionName
          );
          if (
            extractOption &&
            (
              extractOption.macroType === this.selectedCategory ||
              extractOption.taskType === this.selectedCategory ||
              extractOption.supports && extractOption.supports.includes(this.selectedCategory)
            )
          ) {
            extractCategory = true;
          }
        }
        if (extractCategory || this.selectedCategory === 'all') {
          this.extractionAgents = [];
          this.totalExtractionAgents = 0;
          this.getExtractionAgents(this.getDynamicExtractFilter());
        }
      }, 300
    ),
  },

  mounted() {
    this.getExtractionAgents();
  },

  methods: {
    createSubstrings(str) {
      let substrings = [];
      for (let i = str.length; i > 0; i--) {
        substrings.push(str.slice(0, i));
      }
      return substrings;
  },

    getDynamicExtractFilter() {
      // this is necessary in order to properly filter agents on the back
      const substrings = this.createSubstrings('extract');
      const filterArr = this.trimmedNodeFilter.split(' ');
      let filter = this.trimmedNodeFilter;
      for (let i = 0; i < substrings.length; i++) {
        if (filterArr.includes(substrings[i])) {
          filter = this.trimmedNodeFilter.replace(substrings[i], '');
          break;
        }
      }
      return filter.trim();
    },

    getMoreExtractionAgents() {
      if (!this.gettingExtractionAgents) {
        this.getExtractionAgents();
      }
    },

    async getExtractionAgents(nameFilter = '') {
      this.gettingExtractionAgents = true;
      try {
        const offset = this.extractionAgents.length;
        if (offset && offset >= this.totalExtractionAgents) {
          return
        }
        const limit = 20;
        const response = await DocTypeAPI.get(
          limit, offset, nameFilter, null, true, true,
        );
        if (offset > 0) {
          this.extractionAgents = [...this.extractionAgents, ...response.data];
        } else {
          this.extractionAgents = response.data;
        }
        this.totalExtractionAgents = parseInt(
          response.headers['x-total-count'], 10
        );
        if (nameFilter === '') {
          this.totalDisplayExtractionAgents = this.totalExtractionAgents;
        }
        this.gotExtractionAgents = true;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.gettingExtractionAgents = false;
      }
    },

    createOption(name, option, value, owner) {
      return {
        name,
        owner,
        value,
        config: option.config,
        macroType: option.macroType,
        taskType: option.taskType,
        supports: option.supports,
      }
    },

    addLoadingExtractionAgentOptions(option, options) {
      const loadingOptions = new Array(
        this.totalExtractionAgents - this.extractionAgents.length
      ).fill(
        this.createOption('ExtractLoading',
          option,
          {...option.value, doctype: null },
          this.orgName,
        )
      );
      loadingOptions.forEach(o => options.push(o));
    },

    getDynamicExtractOptions(option) {
      const options = [];
      this.extractionAgents.forEach(agent => {
        const newValue = {...option.value};
        const name = this.$t('workflows.steps.extract_agent', { agent: agent.name });
        newValue.config = { doctype: agent.id };
        newValue.name = name;
        options.push(
          this.createOption(name, option, newValue, this.orgName)
        );
      });
      this.addLoadingExtractionAgentOptions(option, options);
      return options;
    },

    getCategoryOptionCount(category) {
      let baseCount = this.nodeOptions.filter(
        o => {
          return (
            o.macroType === category ||
            o.taskType === category ||
            o.supports && o.supports.includes(category)
          )
        }
      ).length;
      const extractOption = this.nodeOptions.find(
        o => o.name === this.extractOptionName
      );
      if (
        extractOption &&
        (
          extractOption.macroType === category ||
          extractOption.taskType === category ||
          extractOption.supports && extractOption.supports.includes(category)
        )
      ) {
        baseCount += this.totalDisplayExtractionAgents;
      }
      return baseCount;
    },

    handleAddNode(value) {
      this.$emit('add-node', value);
    },

    selectCategory(category) {
      this.selectedCategory = category;
      this.nodeFilter = '';
    },
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    nodeOptions: {
      type: Array,
      required: true,
    },

    isDraft: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['update:model-value', 'add-node'],
};
</script>

<style lang="scss" scoped>
.dialog-card {
  height: 100%;
  background-color: #f4f5f9;

  .node-dialog-sidebar {
    width: 250px;
    height: 500px;
    overflow-y: auto;
    position: fixed;
    left: 30px;
    top: 88px;

    .node-category {
      padding: 10px 12px;
    }

    .node-category:hover,
    .selected-category {
      background-color: rgb(var(--v-theme-primary-lighten2));
    }
  }

  .node-filter {
    background-color: white !important;
    outline: none;
    border: 1px solid #bbb;
    border-radius: 5px;
    transition: border 0.05s;
    font-size: 14px;
    margin-top: -20px;
    width: 300px;

    &:focus {
      border: 1px solid rgb(var(--v-theme-primary));
    }

    &:hover:not(:focus) {
      border: 1px solid #666;
    }

    &::placeholder {
      color: #ccc;
      font-style: italic;
    }
  }

  .node-dialog-options {
    width: 660px;
    margin-left: 260px;
    height: 500px;
    overflow-y: auto;
    background-color: rgb(var(--v-theme-grey-lighten2));
    padding-top: 15px;
    padding-left: 15px;
    padding-bottom: 5px;
    border-radius: 4px;
  }
}
</style>
