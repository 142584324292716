import { http } from '@/plugins/axios';

const BASE_PATH = 'dataset/';

export class DatasetAPI {
  static get(
    offset,
    limit,
    nameFilter = '',
    status = null,
    sortDesc = true,
    noAuth = false,
    external = false,
    token = null
  ) {
    return http
      .get(BASE_PATH, {
        params: {
          name_filter: nameFilter,
          sort_desc: sortDesc,
          status,
          noAuth,
          external,
          token,
          offset,
          limit,
        }
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static getSingle(id) {
    return http
      .get(`${BASE_PATH}${id}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static export(id, modelName = null, newModelVersion = null) {
    return http
      .post(`${BASE_PATH}${id}/export/`, null, {
        params: {
          model_name: modelName,
          new_model_version: newModelVersion,
        },
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static put(id, name) {
    return http
      .put(`${BASE_PATH}${id}/`, { name })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getClassify(
    offset,
    limit,
    nameFilter = '',
    sortDesc = true,
    noAuth = false,
    external = false,
    token = null
  ) {
    return http
      .get(`${BASE_PATH}classify/`, {
        params: {
          name_filter: nameFilter,
          sort_desc: sortDesc,
          noAuth,
          external,
          token,
          offset,
          limit,
        }
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }


  static trainClassify(datasets, modelName, features, useAllDatasets = false, excludedDatasetIds = []) {
    return http
      .post(`${BASE_PATH}classify/train/`, {
        dataset_ids: datasets,
        excluded_dataset_ids: excludedDatasetIds,
      },
        {
          params: {
            model_name: modelName,
            use_all_datasets: useAllDatasets,
            features,
            excluded_dataset_ids: excludedDatasetIds,
          },
        })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }


  static uploadZip(file, config) {
    const formData = new FormData();
    formData.append("zip_file", file);
    return http
      .post(`${BASE_PATH}zip/`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: config,
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
