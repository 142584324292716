<template>
  <div
    class="custom-select clickable noselect"
    @click="toggleSelectOn"
  >
    <div class="action-name ellipsis">
      <span :style="{'font-style': numberOfActions ? 'normal' : 'italic' }">
        {{ selectedAction.name }}
      </span>
    </div>
    <v-icon
      class="open-icon"
      size="17"
    >
      fas fa-chevron-down
    </v-icon>
    <v-icon
      v-if="selectedName !== ''"
      class="clear-icon"
      size="13"
      @click.stop="selectedName = ''"
    >
      fas fa-times-circle
    </v-icon>
    <div
      v-if="selectOn"
      ref="selectPanel"
      class="select-panel"
      tabindex="0"
      @focusout="selectOn = false"
      @scroll="handleScroll"
    >
      <div
        v-for="(action, i) in actions"
        :ref="`Option${i}`"
        :key="action.id"
        class="option ellipsis"
        :value="action.id"
        @click="selectedName = action.name"
      >
        {{ action.name }}
      </div>
    </div>
  </div>
</template>

  <script>
  import { STATE_OPTIONS, EXTRA_ACTION_OPTIONS, createStepOption} from '@/utils/WorkflowsUtils';

 import { WorkflowAPI } from '@/API/workflows/WorkflowAPI';

 const pythonCodeTemplate = `def execute_action(job, input):
  return StepActionType.done, {
      # Add data here          
  }`;

  export default {
    name: 'WorkflowActionSelect',

    data() {
        return ({
            selectedName: '',
            selectOn: false,
            actions: [],
            loading: false,
        })
    },
    computed: {
        numberOfActions() {
            return this.actions.length;
        },

        selectedAction(){
            if (this.selectedName === ''){
                return { name: this.$t('workflows.select_action')};
            }
            const action = this.actions.find(action => action.name === this.selectedName);
            return action || { name: this.$t('workflows.select_action')};
        }

    },

    watch: {
      selectedName(){
        this.$nextTick(() => {
          this.$emit('selectedChanged', this.selectedAction);
        });
        setTimeout(() => this.selectOn = false, 10);
      }
    },
    async created() {
        await this.getStepOptions();
        this.selectedName = this.selected;
    },

    methods: {
      async getActions() {
        try {
          return await WorkflowAPI.getActions();
        } catch (error) {
          return [];
        }
      },

      async getStepOptions() {
        const actions = await this.getActions();
        let actionOptions = [];
        actions.forEach(action => {
          if (
            action.action_type !== 'state' &&
            (!action.action || !action.action.startsWith('dummy'))
          ) {
            let name = this.$te(`workflows.steps.${action.action}`) ? this.$t(`workflows.steps.${action.action}`) : action.action;
            let value = {
              action: action.action,
              action_type: action.action_type,
            }
            if (!value.action && action.action_type === 'python') {
              value = {
                action_type: 'python',
                action: pythonCodeTemplate,
                action_name: 'custom-code',
              };
              name = this.$t(`workflows.steps.${value.action_name}`);
            }
            actionOptions.push(createStepOption(name, action, value));
          }
        });
        const nodeOptions = [
          ...actionOptions,
          ...EXTRA_ACTION_OPTIONS,
          ...STATE_OPTIONS,
        ];
        nodeOptions.sort((a, b) => {
          const textA = a.name.toUpperCase();
          const textB = b.name.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.actions = nodeOptions;
      },

      toggleSelectOn() {
        this.selectOn = !this.selectOn;
        if (this.selectOn) {
            setTimeout(() => {
            this.$refs.selectPanel.focus();
            }, 50);
        }
    }
    },
    props: {
        selected: {
            type: String,
            default: ''
        }
    },
    emits: ['selectedChanged'],
}
</script>

<style lang="scss" scoped>
  .custom-select {
    height: 40px;
    width: 300px;
    border-radius: 4px;
    border: solid 1px #C8C8C8;
    background-color: white;
    padding: 8px;
    padding-right: 30px;
    font-size: 0.9rem;
    position: relative;
    margin-bottom: 20px;

    .action-name {
      max-width: 250px;
    }

    .open-icon {
      position: absolute;
      right: 11px;
      top: 11px;
      color: rgb(var(--v-theme-dark));
      opacity: var(--v-medium-emphasis-opacity);
    }

    .clear-icon {
      position: absolute;
      right: 32px;
      top: 13px;
      opacity: 0.75;
    }

    .select-panel {
      width: 300px;
      max-height: 280px;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 999;
      background-color: white;
      border-radius: 4px;
      border: solid 1px #C8C8C8;
    }

    .select-panel:focus, input:focus{
      outline: none;
    }

    .select-panel .option {
      height: 40px;
      padding: 8px;
      background-color: white !important;
      box-shadow: 0 0 10px 100px white inset;
      color: black !important;
    }
  }
</style>

