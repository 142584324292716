import { http } from '@/plugins/axios';

const BASE_PATH = 'auth/api/v1/';

export class UserAPI {
  static getOneUser(id) {
    return http
      .get(`${BASE_PATH}users/${id}/`,
      { globalErrorHandlingOptOut: true })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getCurrentUser() {
    return http
      .get(`${BASE_PATH}users/me/`,
      { globalErrorHandlingOptOut: true })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getAllUsers(
    limit, offset, emailHas = null, orgId = null, sortDesc = true, role = null,
  ) {
    return http
      .get(`${BASE_PATH}users/`,
      {
        params: {
          limit,
          offset,
          email_has: emailHas,
          org_id: orgId,
          sort_desc: sortDesc,
          role
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static createUser(payload) {
    return http
      .post(`${BASE_PATH}users/`, payload)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static deleteUser(userId) {
    return http
      .delete(`${BASE_PATH}users/${userId}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static editProfile(editedUser) {
    return http
      .put(`${BASE_PATH}users/account/`, editedUser)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static editUser(params) {
    return http
      .put(`${BASE_PATH}users/${params.id}/admin/`, params)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
