<template>
  <div class="business-rules-view">
    <TableActions
      type="businessRules"
      :number-of-selected="selected.length"
      @edit-click="handleEditName"
      @delete-click="deleteDialog = true"
      @create-click="handleCreateButton"
      @filter-change="(filter) => trimmedFilter = filter"
      @filter-enter="handleEnter"
    />
    <TableWithFooter
      :loading="loading"
      :paginated-items-length="paginatedRules.length"
      :total="totalRules"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
    >
      <template #header>
        <v-col cols="auto">
          <SortButton v-model="sortDesc" />
          <v-checkbox
            v-model="allSelected"
            class="mt-0"
            @change="toggleSelectAll"
            hide-details
          />
        </v-col>
        <v-col cols="5">
          {{ $t('businessRules.name') }}
        </v-col>
        <v-col cols="2">
          {{ $t('businessRules.type') }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in paginatedRules"
            :key="item.id"
            class="table-row fade-in table-row-height"
          >
            <v-col cols="auto">
              <v-checkbox
                v-model="item.selected"
                class="left-gap mt-0"
                @change="handleSelect"
                hide-details
              />
            </v-col>
            <v-col
              v-if="item.status === 'incomplete'"
              cols="5"
            >
              <v-tooltip bottom>
                <template #activator="{ props }">
                  <v-icon
                    style="margin-right: 5px;"
                    color="primary"
                    size="16"
                    v-bind="props"
                  >
                    fas fa-exclamation-circle
                  </v-icon>
                </template>
                {{ $t('businessRules.incomplete') }}
              </v-tooltip>
              <ItemName
                :key="item.id"
                style="width: calc(100% - 21px);"
                :item="item"
                :editing-allowed="!!(item.selected)"
                :editing="editingRule === item.id"
                @save-file-name="saveName"
                @name-click="configureBusinessRule({ruleId: item.id}) "
              />
            </v-col>
            <v-col
              v-else-if="item.status === 'error'"
              cols="5"
            >
              <v-tooltip bottom>
                <template #activator="{ props }">
                  <v-icon
                    style="margin-right: 5px;"
                    color="primary"
                    size="16"
                    v-bind="props"
                  >
                    fas fa-exclamation-circle
                  </v-icon>
                </template>
                {{ $t('businessRules.error') }}
              </v-tooltip>
              <ItemName
                :key="item.id"
                style="width: calc(100% - 21px);"
                :item="item"
                @save-file-name="saveName"
                @name-click="configureBusinessRule({ruleId: item.id}) "
              />
            </v-col>
            <v-col
              v-else
              cols="5"
            >
              <ItemName
                :key="item.id"
                style="width: 100%"
                :item="item"
                :editing-allowed="!!(item.selected)"
                :editing="editingRule === item.id"
                @save-file-name="saveName"
                @name-click="configureBusinessRule({ruleId: item.id}) "
              />
            </v-col>
            <v-col cols="2">
              {{ $t(`businessRules.${item.type}`) }}
            </v-col>
            <v-col cols="4">
              <v-tooltip>
                <template #activator="{ props }">
                  <code
                    v-if="['internal', 'subgroup'].includes(item.type) && !loading"
                    v-bind="props"
                    :ref="`rule_logic_${item.id}`"
                    class="code-box ellipsis language-javascript pa-0"
                    lang="javascript"
                    style="word-break: break-all !important; background-color: white;"
                  >
                    {{ parseJsonLogic(item.logic, 1, item.type, dataPoints, labelGroups).slice(1, -1) }}
                  </code>
                </template>
                <div style="max-width: 300px;">
                  <code
                    v-if="['internal', 'subgroup'].includes(item.type) && !loading"
                    :ref="`rule_logic_${item.id}`"
                    class="code-box language-javascript pa-0"
                    lang="javascript"
                    style="word-break: break-all !important;"
                  >
                    {{ parseJsonLogic(item.logic, 1, item.type, dataPoints, labelGroups).slice(1, -1) }}
                  </code>
                </div>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
    <ConfirmDialog
      v-model="deleteDialog"
      :title="$t('businessRules.delete')"
      :message="$t('businessRules.delete_confirmation')"
      @confirm="deleteRules"
    />
  </div>
</template>

<script>
import '@/assets/recital_highlights.css';
import _ from 'lodash';
import hljs from 'highlight.js';
import { http } from '@/plugins/axios';
import { BusinessRuleAPI } from '@/API/extract/BusinessRuleAPI';
import { DataPointAPI } from '@/API/extract/DataPointAPI';
import { ExtractionGroupAPI } from '@/API/extract/ExtractionGroupAPI';
import { parseJsonLogic } from '@/utils/BusinessRuleUtils';
import SortButton from '@/components/common/elements/Tables/SortButton';
import TableActions from '@/components/common/elements/Tables/TableActions';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import ConfirmDialog from "@/components/common/elements/Tables/ConfirmDialog";
import ItemName from '@/components/common/elements/General/ItemName';

export default {
  name: 'BusinessRulesView',

  components: {
    TableActions,
    TableWithFooter,
    ConfirmDialog,
    ItemName,
    SortButton
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      sortDesc: true,
      node: {'+': [0]},
      allSelected: false,
      deleteDialog: false,
      paginatedRules: [],
      dataPoints: [],
      labelGroups: [],
      totalRules: 0,
      trimmedFilter: '',
      loading: false,
      editingRule: -1,
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    totalRulesDisplay: {
      get() {
        return this.$store.getters.totalRulesDisplay;
      },
      set(total) {
        this.$store.commit("setTotalRulesDisplay", total);
      }
    },

    selected: {
      get() {
        if (this.paginatedRules.length > 0) {
          return this.paginatedRules.filter(item => item.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },
  },

  watch: {
    sortDesc(desc) {
      this.getRules(desc, true);
    },

    totalRules(total) {
      if (this.trimmedFilter === '') {
        this.totalRulesDisplay = total;
      }
    },

    itemsPerPage() {
      this.resetCurrentPage();
      this.getRules(this.sortDesc, true);
    },

    currentPage(page) {
      this.allSelected = false;
      this.paginatedRules.forEach(item => {
        item.selected = this.allSelected;
      });
      this.getRules(
        this.sortDesc, true, (page - 1) * this.itemsPerPage, this.itemsPerPage
      );
    },

    trimmedFilter: _.debounce(
      function() {
        this.resetCurrentPage();
        this.getRules(this.sortDesc, true);
      }, 300
    ),
  },

  async mounted() {
    this.loading = true;
    try {
      this.dataPoints = await DataPointAPI.get(this.$route.params.id);
      this.labelGroups = await ExtractionGroupAPI.get(this.$route.params.id);
      await this.getRules(this.sortDesc);
    } catch (error) {
      this.dataPoints = this.dataPoints || [];
      this.labelGroups = this.labelGroups || [];
      this.paginatedRules = this.paginatedRules || [];
    } finally {
      this.loading = false;
    }
  },

  methods: {
    parseJsonLogic,
    async getRules(sortDesc, force) {
      if (force) {
        this.loading = true;
      }
      try {
        const [rules, total] = await BusinessRuleAPI.get(
          this.currentPage - 1,
          this.itemsPerPage,
          this.$route.params.id,
          this.trimmedFilter,
          sortDesc,
        );
        this.totalRules = total;
        this.paginatedRules = rules;
        this.$nextTick(() => {
          hljs.configure({
            languages: ['javascript'],
            ignoreUnescapedHTML: true,
          });
          this.paginatedRules.forEach(rule => {
            const ruleLogicArr = this.$refs[`rule_logic_${rule.id}`];
            if (ruleLogicArr && ruleLogicArr.length > 0) {
              hljs.highlightElement(ruleLogicArr[0]);
            }
          });
        });
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.loading = false;
      }

    },

    async deleteRules() {
      await Promise.all(this.selected.map(async r => {
        try {
          return await http.delete(`system_2/business_rule/${r.id}`);
        } catch (error) {
          return
        }
      }));
      this.finishDeletion();
    },

    async finishDeletion() {
      const { currentPage, itemsPerPage} = this;
      await this.getRules(this.sortDesc, true);
      const lastPage = Math.max(1, Math.ceil(this.totalRules / itemsPerPage));
      this.currentPage = Math.min(currentPage, lastPage);
      this.allSelected = false;
      this.deleteDialog = false;
      await this.$store.commit(
        'setSuccessMessage', this.$t('businessRules.deleted_message')
      );
      this.$store.commit('setSuccessSnackbar', true);
    },

    resetCurrentPage() {
      this.currentPage = 1;
      this.allSelected = false;
      this.paginatedRules.forEach(item => {
        item.selected = false;
      });
    },

    handleSelect() {
      this.allSelected = this.paginatedRules.every(item => item.selected);
    },

    async saveName(id, newName) {
      if (newName !== '') {
        this.$store.commit('setLoadingScreen', true);
        try {
          await http.put(`system_2/business_rule/${id}/`, { name: newName.trim() });
          const rule = this.paginatedRules.find(wf => wf.id === id);
          rule.name = newName;
          rule.selected = false;
          await this.$store.commit(
            'setSuccessMessage', this.$t('businessRules.renamed')
          );
          this.$store.commit('setSuccessSnackbar', true);
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        } finally {
          this.$store.commit('setLoadingScreen', false);
        }
      }
    },

    toggleSelectAll() {
      this.paginatedRules.forEach(item => {
        item.selected = this.allSelected;
      });
      this.paginatedRules = [...this.paginatedRules];
    },

    handleEnter() {
      if (this.paginatedRules.length > 0) {
        this.configureBusinessRule(
          { ruleId: this.paginatedRules[0].id }
        );
      }
    },

    configureBusinessRule(params) {
      this.$router.push({
        name: 'ConfigureBusinessRule',
        params,
      });
    },

    handleEditName() {
      this.editingRule = this.selected[0].id;
    },

    handleCreateButton() {
      this.$router.push({
        name: 'ConfigureBusinessRule',
        params: { ruleId: 'new' },
      });
    },
  }
}
</script>
<style scoped>
.code-box {
  max-width: 100%;
}
</style>
