<template>
  <div class="copy-type-view page-padding py-7">
    <BreadcrumbComponent />
    <h2 class="text-h2 top-gap-lg">
      {{ $t('docTypes.duplicate') }} {{ $store.getters.copySource.name }}
    </h2>
    <div class="table-tools d-flex align-center justify-space-between top-gap">
      <div>
        <v-text-field
          v-model="typeName"
          class="right-gap inline-middle"
          style="width: 280px"
          variant="outlined"
          color="primary"
          density="compact"
          :placeholder="$t('docTypes.type_name')"
          @keydown.enter="addNew"
        />
        <v-btn
          style="margin-top: -20px"
          color="primary"
          :disabled="typeName.trim() === ''"
          @click="addNew"
          rounded
        >
          {{ $t('create') }}
        </v-btn>
      </div>
    </div>
    <div
      class="radio-box d-flex align-center bottom-gap-sm"
      style="padding-right: 9px !important; max-width: max-content;"
    >
      <v-checkbox
        v-model="generativeCopy"
        class="right-gap-sm"
        color="primary"
        :label="$t('docTypes.copy_extractors_as_generative')"
        hide-details
      />
    </div>
    <GenerativeWarning v-if="generativeCopy" />
    <FileUploader
      class="mt-5"
      @files-selected="handleFilesSelect"
    />
  </div>
</template>

<script>
import { FileAPI } from '@/API/extract/FileAPI';
import { DocTypeAPI } from '@/API/extract/DocTypeAPI';

import FileUploader from '@/components/common/elements/Forms/FileUploader';
import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent";
import GenerativeWarning from '@/components/extract/elements/Extractors/GenerativeWarning';

export default {
  name: 'CopyTypeView',

  components: {
    FileUploader,
    BreadcrumbComponent,
    GenerativeWarning,
  },

  data() {
    return ({
      typeName: '',
      id: -1,
      files: [],
      generativeCopy: false,
    })
  },

  mounted() {
    if (!this.$store.getters.copySource
      || !this.$store.getters.copySource.id
      || !this.$store.getters.copySource.name) {
      this.$router.push({name: 'CreateDoctype'});
    }
    this.$store.commit('setBreadcrumb',
    [
      { title: this.$t('breadcrumb.home'), href: {name: 'Suite'} },
      { title: this.$t('docTypes.create'), href: {name: 'CreateDoctype'} },
      { title: this.$t('docTypes.duplicate') },
    ]
    );
  },

  methods: {
    async addNew() {
      try {
        const { data: id} = await DocTypeAPI.duplicate(this.$store.getters.copySource.id, this.typeName, this.generativeCopy);
        this.id = id;
        this.$store.commit('setSuccessMessage', this.$t('docTypes.added'));
        this.$store.commit('setSuccessSnackbar', true);
        if (this.files.length > 0) {
          await this.uploadFiles();
        }
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async handleFilesSelect(files) {
      this.files = files;
      if (this.id >= 0) {
        await this.uploadFiles();
      }
    },

    async uploadFiles() {
      const length = this.files.length;
      if (length > 0) {
        if (typeof this.files === 'object') {
          this.files = Object.values(this.files).map(item => item);
        }
        for (let i = 0; i < length; i++) {
          await this.startUpload(this.files[i]);
        }
        this.$router.push({
          name: 'DocType',
          params: {
            id: this.id,
            tab: 'documents',
          },
        });
      }
    },

    async startUpload(file) {
      this.$store.commit('setLoadingScreen', true);
      try {
        await FileAPI.post(this.id, file);
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },
  }
}
</script>
