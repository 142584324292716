<template>
  <div class="users-table top-gap-lg">
    <TableActions
      type="users"
      :number-of-selected="selected.length"
      @edit-click="editDialog = true"
      @delete-click="deleteDialog = true"
      @create-click="createDialog = true"
      @logs-click="logsDialog = true"
      @filter-change="(filter) => trimmedFilter = filter"
      @org-change="(id) => orgFilter = id"
    />
    <TableWithFooter
      :loading="loading"
      :paginated-items-length="paginatedUsers.length"
      :total="totalUsers"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
    >
      <template #header>
        <v-col cols="auto">
          <SortButton
            v-model="sortDesc"
            style="margin-top: -5px;"
          />
          <v-checkbox
            v-model="allSelected"
            class="mt-0"
            @change="toggleSelectAll"
            hide-details
          />
        </v-col>
        <v-col
          class="justify-center"
          cols="auto"
        >
          {{ $t('status') }}
        </v-col>
        <v-col cols="4">
          {{ $t('forms.email') }}
        </v-col>
        <v-col cols="4">
          {{ $t('forms.name') }}
        </v-col>
        <v-col cols="2">
          {{ $t('role') }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in paginatedUsers"
            :key="item.id"
            class="table-row fade-in table-row-height"
          >
            <v-col cols="auto">
              <v-checkbox
                v-model="item.selected"
                class="left-gap mt-0"
                :style="{ opacity: isEditable(item) ? 1 : 0.5 }"
                :disabled="!isEditable(item)"
                @change="handleSelect"
                hide-details
              />
            </v-col>
            <v-col
              class="justify-center"
              style="max-width: 64.24px !important;"
            >
              <v-tooltip
                v-if="item.status === 'expired'"
                color="#423F4F"
                right
              >
                <template #activator="{ props }">
                  <v-icon
                    style="top: -1px"
                    color="primary"
                    size="16"
                    v-bind="props"
                  >
                    fas fa-clock
                  </v-icon>
                </template>
                <span style="color: white">
                  {{ $t('users.status.expired') }}
                </span>
              </v-tooltip>
              <v-tooltip
                v-else-if="item.status === 'blocked'"
                color="#423F4F"
                right
              >
                <template #activator="{ props }">
                  <v-icon
                    style="top: -1px"
                    color="primary"
                    size="16"
                    v-bind="props"
                  >
                    fa fa-ban
                  </v-icon>
                </template>
                <span style="color: white">
                  {{ $t('users.status.blocked') }}
                </span>
              </v-tooltip>
              <v-tooltip
                v-else-if="item.status === 'open'"
                color="#423F4F"
                right
              >
                <template #activator="{ props }">
                  <v-icon
                    style="top: -1px"
                    color="primary"
                    size="16"
                    v-bind="props"
                  >
                    fas fa-user
                  </v-icon>
                </template>
                <span style="color: white">
                  {{ $t('users.status.open') }}
                </span>
              </v-tooltip>
            </v-col>
            <v-col cols="4">
              <ItemName
                :key="item.id"
                :item="{...item, name: `${item.email}`}"
                :clickable="false"
              />
            </v-col>
            <v-col cols="4">
              <ItemName
                :key="item.id"
                :item="{...item, name: `${item.first_name[0].toUpperCase()}${item.first_name.slice(1)} ${item.last_name[0].toUpperCase()}${item.last_name.slice(1)}`}"
                :clickable="false"
                :show-id="false"
              />
            </v-col>
            <v-col cols="2">
              {{ $t(`roles.${item.role}`) }}
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
    <GetLogsDialog
      v-model="logsDialog"
      @confirm="getSearchLogs"
    />
    <CreateUserDialog
      v-model="createDialog"
      @close="createDialog = false"
      @refresh="refreshUsers"
    />
    <EditUserDialog
      v-model="editDialog"
      :edited-user="editedUser"
      @close="editDialog = false"
      @refresh="editRefresh"
    />
    <ConfirmDialog
      v-model="deleteDialog"
      :title="$t('users.delete')"
      :message="$t('users.delete_confirmation.message')"
      @confirm="deleteUsers"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { http } from '@/plugins/axios';
import { UserAPI } from '@/API/authenticator/UserAPI';
import { SearchAPI } from "@/API/search/SearchAPI";

import SortButton from '@/components/common/elements/Tables/SortButton';
import TableActions from '@/components/common/elements/Tables/TableActions';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import CreateUserDialog from '@/components/common/elements/Users/CreateUserDialog';
import EditUserDialog from '@/components/common/elements/Users/EditUserDialog';
import GetLogsDialog from '@/components/search/elements/Users/GetLogsDialog';
import ConfirmDialog from "@/components/common/elements/Tables/ConfirmDialog";
import ItemName from '@/components/common/elements/General/ItemName';

export default {
  name: 'UsersTable',

  components: {
    SortButton,
    TableActions,
    TableWithFooter,
    CreateUserDialog,
    EditUserDialog,
    ConfirmDialog,
    GetLogsDialog,
    ItemName,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      sortDesc: true,
      orgFilter: -1,
      trimmedFilter: '',
      editOn: false,
      editUser: -1,
      deleteDialog: false,
      editDialog: false,
      createDialog: false,
      logsDialog: false,
      totalUsers: 0,
      allSelected: false,
      loading: false,
      paginatedUsers: [],
      forceCompute: Math.random(),
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    user() {
      if (this.$store.getters.loggedInUser) {
        return this.$store.getters.loggedInUser;
      }
      return null;
    },

    editedUser() {
      if (this.selected.length > 0) {
        return this.selected[0];
      }
      return {};
    },

    selected: {
      get() {
        this.forceCompute;
        if (this.paginatedUsers.length > 0) {
          return this.paginatedUsers.filter(item => item.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },
  },

  watch: {
    totalUsers(total) {
      if (this.trimmedFilter === '' && this.orgFilter === -1) {
        this.$emit('totalChange', total);
      }
    },

    sortDesc() {
      this.getUsers();
    },

    itemsPerPage() {
      this.resetCurrentPage();
      this.getUsers();
    },

    currentPage(page) {
      this.allSelected = false;
      this.paginatedUsers.forEach(item => {
        if (this.isEditable(item)) {
          item.selected = this.allSelected;
        }
      });
      this.forceCompute = Math.random();
      this.getUsers(this.itemsPerPage * (page - 1), this.itemsPerPage);
    },

    trimmedFilter: _.debounce(
      function() {
        this.resetCurrentPage();
        this.getUsers();
      }, 300
    ),

    orgFilter: _.debounce(
      function() {
        this.resetCurrentPage();
        this.getUsers();
      }, 300
    ),
  },

  created() {
    this.getUsers();
  },

  methods: {
    async getSearchLogs(timeRange, includeAi) {
      this.logsDialog = false;
      try {
        const response = await SearchAPI.getLogs(timeRange, includeAi);
        const url = window.URL.createObjectURL(new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `logs.xls`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    isEditable(item) {
      if (this.user.role === 'orgadmin' && item.role === 'sysadmin') {
        return false;
      }
      return item.id !== this.user.id;
    },

    async deleteUsers() {
      await Promise.all(this.selected.map(async user => {
        try {
         return await http.delete(`auth/api/v1/users/${user.id}`);
        } catch (error) {
          return
        }
      }));
      this.finishDeletion();
    },

    async finishDeletion() {
      const { currentPage, itemsPerPage} = this;
      await this.getUsers();
      if (Math.ceil(this.totalUsers / itemsPerPage) > this.currentPage ) {
        this.currentPage = currentPage;
      } else {
        this.currentPage = 1;
      }
      this.allSelected = false;
      this.selected.forEach(user => {
        user.selected = false;
      });
      this.deleteDialog = false;
      await this.$store.commit(
        'setSuccessMessage', this.$t('users.successDelete')
      );
      this.$store.commit('setSuccessSnackbar', true);
    },

    editRefresh(newValues) {
      this.editedUser.status = newValues.status;
      this.editedUser.role = newValues.role;
      this.editedUser.selected = false;
    },

    async refreshUsers() {
      this.paginatedUsers = [];
      await this.getUsers();
      this.currentPage = 1;
    },

    async getUsers(
      offset = (this.currentPage - 1) * this.itemsPerPage,
      limit = this.itemsPerPage)
    {
      try {
        this.loading = true;
        let orgId = null;
        if (this.user && this.user.role === 'sysadmin' && this.orgFilter != -1) {
          orgId = this.orgFilter;
        }
        const response = await UserAPI.getAllUsers(
          limit,
          offset,
          this.trimmedFilter || '',
          orgId,
          this.sortDesc,
        );
        this.paginatedUsers = response.data;
        this.totalUsers = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.loading = false;
      }
    },

    resetCurrentPage() {
      this.currentPage = 1;
      this.allSelected = false;
      const users = this.paginatedUsers.map(u => {
        u.selected = false;
        return u;
      });
      this.paginatedUsers = [...users];
    },

    handleSelect() {
      this.allSelected = this.paginatedUsers.filter(u => this.isEditable(u)).every(u => u.selected);
      this.forceCompute = Math.random();
    },

    toggleSelectAll() {
      this.paginatedUsers.filter(u => this.isEditable(u)).forEach(item => {
        item.selected = this.allSelected;
      });
      this.forceCompute = Math.random();
    },
  },

  emits: ['totalChange'],
}
</script>
