<template>
  <div class="datasets-view page-padding py-7">
    <CreateDataset
      v-if="view === 'create'"
      @select-dataset="d => dataset = d"
      @go-back="view = 'datasets'"
    />
    <DatasetsTable
      v-if="view === 'datasets'"
      @open-dataset="openDataset"
      @create="dataset = null; view = 'create'"
      @change-total="total => totalDatasets = total"
    />
  </div>
</template>

<script>
import { DatasetAPI } from '@/API/extract/DatasetAPI';

import dataset_mixin from '@/mixins/dataset.js';

import CreateDataset from '@/components/extract/elements/Datasets/CreateDataset';
import DatasetsTable from '@/components/extract/elements/Datasets/DatasetsTable';

export default {
  name: 'DatasetsView',

  mixins: [dataset_mixin],

  components: {
    CreateDataset,
    DatasetsTable,
  },

  data() {
    return ({
      view: 'datasets',
      totalDatasets: 0,
      dataset: null,
    });
  },

  computed: {
    datasetId() {
      return this.dataset ? parseInt(this.dataset.id, 10) : -1
    },
  },

  watch: {
    totalDatasets() {
      this.getDatasetSubview();
    },

    dataset() {
      this.getDatasetSubview();
    },
  },

  async mounted() {
    await this.checkIfDatasets();
    this.view = this.totalDatasets === 0 || this.$route.path.endsWith('create') ? 'create' : 'datasets';
  },

  methods: {
    openDataset(ds) {
      this.dataset = ds;
    },

    async checkIfDatasets() {
      try {
        this.$store.commit('setLoadingScreen', true);
        const response = await DatasetAPI.get(0, 1);
        this.totalDatasets = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    getDatasetSubview() {
      if (!this.dataset) {
        this.view = this.totalDatasets === 0 ? 'create' : 'datasets';
      } else {
         this.$router.push({
          name: "Dataset",
          params: {
            id: this.dataset.id,
          },
        });
      }
    },
  },
}
</script>
