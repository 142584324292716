
import { http } from '@/plugins/axios';

const BASE_PATH = 'dataset/entry/';

export class EntryAPI {
  static get(
    datasetId,
    offset,
    limit,
    nameFilter = '',
    annotatedLabelFilter = null,
    statusFilter = null,
    sortDesc = true,
    cutoffId = null,
  ) {
    return http
      .get(BASE_PATH, {
        params: {
          dataset_id: datasetId,
          name_filter: nameFilter,
          annotated_label_filter: annotatedLabelFilter,
          status_filter: statusFilter,
          sort_desc: sortDesc,
          cutoff_id: cutoffId,
          offset,
          limit,
        }
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static getSingle(id) {
    return http
      .get(`${BASE_PATH}${id}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static delete(id) {
    return http
      .delete(`${BASE_PATH}${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static postWords(id, pageNb, location) {
    return http
      .post(`${BASE_PATH}${id}/words/?page_nb=${pageNb}`, location)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static post(datasetId, file) {
    const formData = new FormData();
    formData.append("entry_in", file);
    formData.append("dataset_id", datasetId);
    const headerConfig = { headers: { 'Content-Type': 'multipart/form-data' } }
    return http
      .post(BASE_PATH, formData, headerConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
