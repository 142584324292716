<template>
  <div class="create-doctype-view page-padding py-7">
    <BreadcrumbComponent />
    <div style="max-width: 740px">
      <h2 class="text-h2 top-gap-lg">
        {{ $t('docTypes.title') }}
      </h2>
    </div>
    <div
      v-if="myLibraryVisible"
      class="top-gap-lg"
    >
      <h4 v-if="myLibrary.length > 0">
        {{ $t('docTypes.my_library') }}
      </h4>
      <div class="d-flex align-center top-gap">
        <v-btn
          color="primary"
          class="create-button mr-3"
          style="box-shadow: none !important;"
          variant="outlined"
          @click="handleNewClick"
          rounded
        >
          <v-icon
            size="17"
            start
          >
            fas fa-plus
          </v-icon>
          {{ $t('docTypes.custom') }}
        </v-btn>
        <span class="mr-2">
          {{ $t('docTypes.copy_from_existing') }}
        </span>
        <DoctypeSelect
          type="doctypes"
          :docs="myLibrary"
          :selected="selectedDoctype"
          :show-icon="false"
          :select-first="false"
          @get-more="getMoreDocTypes"
          @selected-changed="(id) => handleCopyClick(id, 'myLibrary')"
        />
      </div>
    </div>
    <div
      v-if="customModelsVisible"
      class="top-gap-lg"
    >
      <h4>{{ $t('models.custom') }}</h4>
      <div class="d-flex align-center top-gap">
        <span class="mr-2">
          {{ $t('docTypes.create_from_existing_model') }}
        </span>
        <CustomSelect
          style="width: 300px;"
          :items="customModels"
          :selected="selectedModel"
          @selected-changed="handleCustomModelClick"
          @get-more="(nameFilter, reset) => getMoreModels(numberOfCustomModels, nameFilter, reset)"
        />
        <v-select
          v-model="selectedModelVersion"
          class="mt-0 ml-3 clickable inline-middle"
          style="width: 300px;"
          variant="outlined"
          color="primary"
          density="compact"
          item-title="version"
          :disabled="selectedModel === 0"
          :items="modelVersions"
          :placeholder="$t('models.select_version')"
          @update:model-value="handleModelVersionClick"
        />
      </div>
    </div>
    <div
      v-if="selectedModelVersion"
      style="position: relative;"
    >
      <div
        class="group-doctype-list-container inline-top top-gap-lg"
      >
        <h4>{{ $t('docTypes.pre_create_groups') }}</h4>
        <div
          ref="groupList"
          class="group-doctype-list top-gap-sm pa-2"
          @scroll="handleGroupScroll"
        >
          <v-checkbox
            v-for="group, i in docTypeGroups"
            :ref="`modelGroup${i}`"
            :key="group.id"
            v-model="selectedGroups"
            color="primary"
            :label="`${group.name} (${group.document_type_name})`"
            :value="group"
          />
          <v-skeleton-loader
            v-if="loadingGroups"
            class="mt-3 inline-middle"
            type="text"
            :width="20"
          />
          <v-skeleton-loader
            v-if="loadingGroups"
            class="mt-3 ml-2 inline-middle"
            type="text"
            :width="Math.floor(Math.random() * (300 - 50 + 1) + 50)"
          />
          <span
            v-if="!loadingGroups && docTypeGroups.length === 0"
            class="no-results-message"
            style="color: #bbb;"
          >
            {{ $t('docTypes.no_doctypes_yet') }}
          </span>
        </div>
      </div>
      <v-btn
        id="create-from-model-button"
        color="primary"
        class="create-button inline-top top-gap-lg"
        variant="outlined"
        @click="handleNewClick"
        rounded
      >
        <v-icon
          size="17"
          start
        >
          fas fa-plus
        </v-icon>
        {{ $t('docTypes.create') }}
      </v-btn>
    </div>
    <div
      v-if="recitalLibraryVisible"
      class="top-gap"
    >
      <h4>{{ $t('docTypes.recital_library') }}</h4>
      <div class="d-flex align-center top-gap">
        <v-btn
          color="primary"
          class="create-button mr-3"
          style="box-shadow: none !important;"
          variant="outlined"
          @click="handleNewClick"
          rounded
        >
          <v-icon
            size="17"
            start
          >
            fas fa-plus
          </v-icon>
          {{ $t('docTypes.recital') }}
        </v-btn>
        <span class="mr-2">
          {{ $t('docTypes.copy_from_existing') }}
        </span>
        <DoctypeSelect
          type="doctypes"
          :docs="recitalLibrary"
          :selected="selectedRecital"
          :show-icon="false"
          @get-more="getMoreRecitalDoctypes"
          @selected-changed="(id) => handleCopyClick(id, 'recitalLibrary')"
        />
      </div>
    </div>
    <div
      v-if="!myLibraryVisible && !recitalLibraryVisible && !customModelsVisible"
      class="top-gap-lg no-results-message"
    >
      {{ $t('docTypes.no_results') }}
    </div>
  </div>
</template>

<script>
import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent";
import DoctypeSelect from "@/components/extract/elements/DocTypes/DocTypeSelect";
import CustomSelect from '@/components/common/elements/Forms/CustomSelect';

import doc_type_mixin from '@/mixins/document_type.js';
import model_mixin from '@/mixins/model.js';
import { http } from '@/plugins/axios';
import _ from "lodash";

export default {
  name: 'CreateDoctypeView',

  mixins: [doc_type_mixin, model_mixin],

  components: {
    DoctypeSelect,
    BreadcrumbComponent,
    CustomSelect,
  },

  data() {
    return ({
      selectedDoctype: -1,
      selectedModel: 0,
      selectedModelVersion: null,
      selectedRecital: -1,
      docTypeGroups: [],
      selectedGroups: [],
      loadingGroups: false,
      docTypeAlreadyRequestGroups: [],
      sortDesc: true,
    })
  },

  computed: {
    customModelsVisible() {
      return this.totalActiveModels > 0;
    },

    recitalLibraryVisible() {
      return this.$store.getters.loggedInUser.role === 'sysadmin';
    },

    myLibraryVisible() {
      return this.myLibrary.length > 0 || this.$store.getters.loggedInUser.role !== 'sysadmin';
    },

    recitalLibrary() {
      return this.$store.getters.recitalLibrary;
    },

    myLibrary() {
      return this.$store.getters.library;
    },

    currentPage: {
      get() {
        return this.$store.getters.currentPage['newDocType'];
      },
      set(currentPage) {
        this.$store.commit('setCurrentPage', { key: 'newDocType', currentPage });
      }
    },

    currentPageRecital: {
      get() {
        return this.$store.getters.currentPage.recitalDoctypes;
      },
      set(currentPage) {
        this.$store.commit('setCurrentPage', { key: 'recitalDoctypes', currentPage });
      }
    },

    itemsPerPage() {
      return this.$store.getters.itemsPerPage.newDocType;
    },

    itemsPerPageRecital() {
      return this.$store.getters.itemsPerPage.recitalDoctypes;
    },

    librarySize() {
      return this.$store.getters.librarySize;
    },

    recitalLibrarySize() {
      return this.$store.getters.recitalLibrarySize;
    },

    userRole() {
      return this.$store.getters.loggedInUser.role;
    },

    groupNameList() {
      return this.docTypeGroups.map(group => group.name);
    },
  },

  watch: {
    trimmedFilter: _.debounce(
      async function() {
        this.currentPage = 1;
        this.setLibrary([], 0);
        await this.getDocTypes(true)
      }, 300
    ),
  },

  async mounted() {
    this.$store.commit('setBreadcrumb',
    [
      { title: this.$t('breadcrumb.home'), href: {name: 'Suite'} },
      {
        title: this.$t('menu.studio')
      },
      {
        title: this.$t('menu.agents')
      },
      {
        title: this.$t('menu.extraction'), href: {name: 'ExtractionAgents'}
      },
      { title: this.$t('docTypes.create') },
    ]
    );
    await this.getCustomModels();
    this.setLibrary([], 0);
    this.$store.commit('setLoadingScreen', true);
    await this.getDocTypes(true);
    await this.getRecitalDoctypes();
    this.$store.commit('setLoadingScreen', false);
  },

  unmounted() {
    window.onscroll = null;
  },

  methods: {
    async getMoreDocTypes() {
      if (this.currentPage < Math.ceil(this.librarySize / this.itemsPerPage)) {
        this.currentPage++;
        await this.getDocTypes(true);
      }
    },

    async getMoreRecitalDoctypes() {
      if (this.currentPageRecital < Math.ceil(this.recitalLibrarySize / this.itemsPerPageRecital)) {
        this.currentPageRecital++;
        await this.getRecitalDoctypes();
      }
    },

    async getModels() {
      await this.getMoreModels(this.numberOfCustomModels);
    },

    async handleGroupScroll() {
      const TOP_DIFFERENCE = 300;
      const docTypeList = this.$refs.groupList;
      const lastOption = this.$refs[`modelGroup${this.docTypeGroups.length - 1}`];
      if (docTypeList && lastOption) {
        const panelTop = docTypeList.getBoundingClientRect().top;
        const lastTop = lastOption[0].$el.getBoundingClientRect().top;
        const lastTopNormalized = lastTop - panelTop;
        if (
          lastTopNormalized < TOP_DIFFERENCE &&
          !this.$store.getters.loadingScreen &&
          !this.loadingGroups
        ) {
          this.loadingGroups = true;
          await this.getMoreDocTypes();
          if (this.docTypeAlreadyRequestGroups.length < this.librarySize) {
            await this.getDocTypeGroups();
          }
          this.loadingGroups = false;
        }
      }
    },

    handleCustomModelClick(id) {
      if (id > 0) {
        const model = this.customModels.find(item => item.id === id);
        if (model.id !== this.selectedModel) {
          this.selectedModel = model.id;
          this.modelVersions = model.versions;
          this.selectedModelVersion = null;
          this.docTypeGroups = [];
          this.selectedGroups = [];
          this.docTypeAlreadyRequestGroups = [];
        }
      }
    },

    async getDocTypeGroups() {
      let groups = [];
      for (let documentType of this.myLibrary) {
        if (!this.docTypeAlreadyRequestGroups.includes(documentType.id)) {
          try {
            const { data } = await http.get('system_2/extraction_groups/', {
              params: {
                document_type_id: documentType.id,
                model_id: this.selectedModel,
                model_version_id: this.selectedModelVersion.id,
              }
            });
            let groupNames = groups.map(group => group.name);
            groups = [
              ...groups,
              ...data
                .filter(
                  group =>
                    !this.groupNameList.includes(group.name) &&
                    !groupNames.includes(group.name)
                )
                .map(group => ({
                  ...group,
                  document_type_name: documentType.name,
                }))
            ];
            this.docTypeAlreadyRequestGroups.push(documentType.id);
          } catch (error) {
            error.handleGlobally && error.handleGlobally();
          }
        }
      }
      this.docTypeGroups = [...this.docTypeGroups, ...groups];
    },

    async handleModelVersionClick(version) {
      if (!this.selectedModelVersion || version !== this.selectedModelVersion.version) {
        this.selectedModelVersion = this.modelVersions.find(v => v.version === version);
        this.docTypeGroups = [];
        this.selectedGroups = [];
        this.docTypeAlreadyRequestGroups = [];

        this.loadingGroups = true;
        if (this.librarySize === 0) {
          await this.getDocTypes(true);
        }
        await this.getDocTypeGroups();
        this.loadingGroups = false;
      }
    },

    handleNewClick() {
      if (this.selectedModel !== 0) {
        this.$store.commit('setSourceModel',
          {
            customModel: this.selectedModel,
            labels: this.selectedModelVersion.labels,
            readingOrder: this.selectedModelVersion.custom_reading_order,
            modelVersion: this.selectedModelVersion.version,
            groupsToCreate: this.selectedGroups,
          }
        );
      }
      this.$router.push({ name: 'newtype' });
    },

    handleCopyClick(id, library) {
      const type = this[library].find(item => item.id === id);
      if (type) {
        this.$store.commit('setCopySource', {
          id: type.id,
          name: type.name,
        });
        this.$router.push({ name: 'copytype' });
      }
    },
  },
}
</script>

<style scoped lang="scss">
.create-doctype-view {
  .no-results-message {
    font-style: italic;
    text-align: center;
  }

  #create-from-model-button {
    position: absolute;
    right: calc(100% - 899px);
    box-shadow: none !important;

    @media screen and (max-width: 1229px) {
      left: auto;
      right: 0;
    }
  }

  .group-doctype-list-container {
    height: 300px;
    width: 35%;

    @media screen and (max-width: 1400px) {
      width: 50%;
    }

    @media screen and (min-width: 2300px) {
      width: 25%;
    }

    .group-doctype-list {
      border: 1px solid #bbb;
      background-color: #fff;
      height: 250px;
      overflow-y: auto;
    }
  }

  h2 {
    color: rgb(var(--v-theme-primary))
  }

  h4 {
    font-weight: 500;
  }
}
</style>
