<template>
  <div>
    <div
      v-for="objectDpInfo in objectValues"
      :key="objectDpInfo.object_dp_name"
    >
      <div
        v-for="(obj, i) in objectDpInfo.object_values"
        :key="i"
      >
        <div
          v-if="obj.page_nb === currentPage && !('adding' in obj)"
          class="highlight-frame"
          :style="getHighlightStyle(obj)"
          @mouseenter="handleObjectEnter(obj)"
          @mouseleave="handleObjectLeave()"
        />
      </div>
    </div>
  </div>
</template>

  <script>
  export default {
    name: "ObjectHighlights",

    methods: {
      getHighlightX(x) {
        return x * this.pageWidth;
      },
      getHighlightY(y) {
        return y * this.pageHeight;
      },
      getHighlightStyle(obj) {
        return {
          borderColor: 'red',
          left: `${this.getHighlightX(obj.location.x_min)}vh`,
          top: `${this.getHighlightY(obj.location.y_min)}vh`,
          width: `${this.getHighlightX(obj.location.x_max - obj.location.x_min)}vh`,
          height: `${this.getHighlightY(obj.location.y_max - obj.location.y_min)}vh`,
          backgroundColor: obj === this.highlightedObject ? 'rgba(255,165,0,0.5)' : ''
        };
      },

      handleObjectEnter(obj) {
        this.$emit('handleObjectEnter', obj);
      },
      handleObjectLeave() {
        this.$emit('handleObjectLeave');
      }
    },

    props: {
      highlightedObject: {
        type: Object,
        default: () => {},
      },

      objectValues: {
        type: Array,
        default: () => [],
      },

      pageWidth: {
        type: Number,
        required: true,
      },

      pageHeight: {
        type: Number,
        required: true,
      },

      currentPage: {
        type: Number,
        required: true,
      },
    },

    emits: [
      'handleObjectEnter',
      'handleObjectLeave',
    ],
  };
  </script>

  <style lang="scss" scoped>
  .highlight-frame {
    position: absolute;
    border-width: 2px;
    border-style: solid;
    cursor: pointer;
    opacity: 0.4;
  }
  </style>
