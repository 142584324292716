
import { http } from '@/plugins/axios';

const BASE_PATH = 'workflows/api/v1/';

export class WorkflowAPI {
  static getWorkflows() {
    return http
      .get(`${BASE_PATH}workflows/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getByUUID(uuid, name, sortDesc, offset=null, limit=null, archived=false) {
    let params = {
      uuid,
      name,
      sort_desc: sortDesc,
      archived,
    };
    if (offset !== null && limit !== null) {
      params = {
        ...params,
        offset,
        limit
      };
    }
    return http
      .get(`${BASE_PATH}workflows/by-uuid`, {
        params,
        paramsSerializer: {
          indexes: null,
        },
      })
      .then(response => Promise.resolve([response.data, parseInt(response.headers['x-total-count'], 10)]))
      .catch(error => Promise.reject(error));
  }

  static getActions() {
    return http
      .get(`${BASE_PATH}workflows/actions/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getWorkflow(id) {
    return http
      .get(`${BASE_PATH}workflows/${id}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static updateWorkflow(id, data) {
    return http
      .patch(`${BASE_PATH}workflows/${id}/`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static restore(id) {
    return http
      .put(`${BASE_PATH}workflows/${id}/restore`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static createWorkflow(data) {
    return http
      .post(`${BASE_PATH}workflows/`, data,)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static publishWorkflow(id) {
    return http
      .post(`${BASE_PATH}workflows/${id}/publish`, {})
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static activateWorkflow(id) {
    return http
      .post(`${BASE_PATH}workflows/${id}/activate`, {})
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static checkIfValid(id) {
    return http
      .post(`${BASE_PATH}workflows/${id}/validate/`, {})
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static forkWorkflow(id) {
    return http
      .post(`${BASE_PATH}workflows/${id}/fork/`, {})
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static archiveWorkflow(uuid) {
    return http
      .post(`${BASE_PATH}workflows/${uuid}/archive`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static restoreWorkflow(uuid) {
    return http
      .post(`${BASE_PATH}workflows/${uuid}/restore`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static deleteWorkflow(uuid) {
    return http
      .delete(`${BASE_PATH}workflows/${uuid}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static deleteArchived() {
    return http
      .delete(`${BASE_PATH}workflows/archived/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static deleteJob(id){
    return http
      .delete(`${BASE_PATH}jobs/${id}/`,)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getSteps(id, action = null, python = false) {
    return http
      .get(`${BASE_PATH}workflows/${id}/steps/`,
        {
            params: {
              action,
              python,
          }
        }
      )
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getWorkflowStep(workflow_id, step_id) {
    return http
      .get(`${BASE_PATH}workflows/${workflow_id}/steps/${step_id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static createStep(workflow_id, data) {
    return http
      .post(`${BASE_PATH}workflows/${workflow_id}/steps/`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static patchStep(workflow_id, step_id, data) {
    return http
      .patch(`${BASE_PATH}workflows/${workflow_id}/steps/${step_id}`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static deleteStep(workflow_id, step_id) {
    return http
      .delete(`${BASE_PATH}workflows/${workflow_id}/steps/${step_id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getTransitions(id) {
    return http
      .get(`${BASE_PATH}workflows/${id}/transitions/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static createTransition(workflow_id, data) {
    return http
      .post(`${BASE_PATH}workflows/${workflow_id}/transitions/`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static deleteTransition(workflow_id, transition_id) {
    return http
      .delete(`${BASE_PATH}workflows/${workflow_id}/transitions/${transition_id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static patchTransition(workflow_id, transition_id, data) {
    return http
      .patch(
        `${BASE_PATH}workflows/${workflow_id}/transitions/${transition_id}`, data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static createJob(formData, queryParams){
    const jobQueryString = new URLSearchParams(queryParams);
    const isFormDataEmpty = !formData || (formData instanceof FormData && ![...formData.entries()].length);

    const jsonHeaders = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const formHeaders = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const headerConfig = isFormDataEmpty ? jsonHeaders : formHeaders;
    const requestData = isFormDataEmpty ? {} : formData;

    return http
      .post(`${BASE_PATH}jobs/?${jobQueryString}`, requestData, headerConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getJobs(workflowId, options = {}){
    const {
      workflowFilter,
      stepFilter,
      statusFilters,
      uuid,
      limit,
      offset,
      sortDesc
    } = options

    const requestParams = {
      workflow_filter: workflowFilter,
      step_filter: stepFilter,
      states: statusFilters,
      limit,
      offset,
      sort_desc: sortDesc
    }

    return http
      .get(`${BASE_PATH}jobs/`, { params: {workflowId, uuid: uuid, ...requestParams}})
      .catch(error => Promise.reject(error));
  }

  static getJob(jobId) {
    return http
      .get(`${BASE_PATH}jobs/${jobId}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getJobHistory(jobId){
    return http
      .get(`${BASE_PATH}jobs/${jobId}/history/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getJobEntry(jobId, entryId, params = null) {
    return http
      .get(`${BASE_PATH}jobs/${jobId}/history/${entryId}`, {
        params: {
          ...params
        }
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getJobFiles(jobId){
    return http
      .get(`${BASE_PATH}jobs/${jobId}/files/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static downloadJobFile(jobId, fileId){
    return http.get(`${BASE_PATH}jobs/${jobId}/files/${fileId}`, {
        responseType: 'arraybuffer'
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
