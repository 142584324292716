<template>
  <div class="stats-view page-padding py-7">
    <PageTabs
      v-model="activeTab"
      :tabs="tabItems"
    />
    <div v-if="activeTab === 'stats'">
      <div
        v-if="userRole === 'sysadmin'"
        class="d-flex align-center top-gap-lg"
      >
        <label
          for="org-select"
          class="mr-4"
        >
          {{ $t('stats.org_select') }}
        </label>
        <v-select
          id="org-select"
          v-model="orgId"
          class="inline-middle mt-0"
          item-title="name"
          item-value="id"
          variant="outlined"
          color="primary"
          density="compact"
          style="width: 400px; margin-top: 0 !important"
          :items="organizations"
        />
      </div>
      <div
        class="d-flex justify-space-around top-gap-lg"
        style="width: 650px;"
      >
        <v-card
          class="mr-6 text-center"
          style="width: 50%;"
        >
          <div class="text-h1 text-center primary--text">
            {{ stats.total_docs }}
          </div>
          <div>
            {{ $t('stats.total_docs') }}
          </div>
        </v-card>
        <v-card
          class="text-center"
          style="width: 50%;"
        >
          <div class="text-h1 text-center primary--text">
            {{ stats.total_pages }}
          </div>
          <div>
            {{ $t('stats.total_pages') }}
          </div>
        </v-card>
      </div>
      <div
        v-if="stats.doctypes && stats.doctypes.length === 0"
        class="warning-message top-gap-lg"
      >
        {{ $t('stats.no_doctypes_warning') }}
      </div>
      <div
        v-if="userRole === 'orgadmin'"
        class="d-flex align-center top-gap-lg"
      >
        <v-text-field
          v-model="typeFilter"
          class="filter-items mr-6"
          style="max-width: 280px"
          variant="outlined"
          color="primary"
          density="compact"
          :placeholder="$t('stats.filter')"
        />
      </div>
      <TableWithFooter
        v-if="userRole === 'orgadmin'"
        :loading="false"
        :paginated-items-length="pages[currentPage]?.length ?? 0"
        :total="filteredTypes.length"
        :current-page="currentPage"
        :items-per-page="itemsPerPage"
        @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
        @change-page="(page) => currentPage = page"
      >
        <template #header>
          <v-col cols="4">
            <SortButton
              v-model="sortDesc"
              class="mr-1"
              style="margin-top: -5px"
            />
            {{ $t('forms.name') }}
          </v-col>
          <v-col>
            {{ $t('stats.nb_docs') }}
          </v-col>
          <v-col>
            {{ $t('stats.nb_pages') }}
          </v-col>
          <v-col>
            {{ $t('stats.nb_google_ocr') }}
          </v-col>
          <v-col style="text-align: center">
            {{ $t('stats.nb_tesseract_ocr') }}
          </v-col>
        </template>
        <template #body>
          <v-container
            class="pa-0"
            fluid
          >
            <v-row
              v-for="item in pages[currentPage]"
              :key="item.id"
              class="table-row fade-in table-row-height"
            >
              <v-col cols="4">
                <ItemName
                  :key="item.doctype_id"
                  class="ml-6 mt-2"
                  style="max-width: calc(100% - 24px)"
                  name-field="doctype_name"
                  :item="item"
                  :editing-allowed="false"
                  :clickable="true"
                />
              </v-col>
              <v-col style="text-align: center">
                {{ item.nb_documents }}
              </v-col>
              <v-col style="text-align: center">
                {{ item.nb_pages }}
              </v-col>
              <v-col style="text-align: center">
                {{ item.nb_google_ocr }}
              </v-col>
              <v-col style="text-align: center">
                {{ item.nb_tesseract_ocr }}
              </v-col>
            </v-row>
          </v-container>
        </template>
      </TableWithFooter>
    </div>
    <TracesView v-if="activeTab == 'traces'" />
    <PerformanceView v-if="activeTab == 'performance'" />
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import SortButton from '@/components/common/elements/Tables/SortButton';
import doc_type_mixin from '@/mixins/document_type.js';
import stats_mixin from '@/mixins/stats.js';
import ItemName from '@/components/common/elements/General/ItemName';
import PageTabs from '@/components/common/elements/General/PageTabs';
import TracesView from '@/components/extract/views/Stats/TracesView';
import PerformanceView from '@/components/extract/views/Stats/PerformanceView';


export default {
  name: 'StatsView',

  mixins: [
    doc_type_mixin,
    stats_mixin,
  ],

  components: {
    ItemName,
    TableWithFooter,
    PageTabs,
    TracesView,
    PerformanceView,
    SortButton,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      sortDesc: true,
      typeFilter: '',
      stats: {},
      activeTab: 'stats',
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    trimmedFilter() {
      return this.typeFilter.trim().toLowerCase();
    },

    filteredTypes() {
      if (this.stats.doctypes) {
        return this.stats.doctypes.filter(t => t.doctype_name.toLowerCase().includes(this.trimmedFilter));
      }
      return [];
    },

    pages: {
      get() {
        const pages = {};
        let page = 0;
        if (this.itemsPerPage > 0) {
          this.filteredTypes.forEach((dp, i) => {
            if (!(i % this.itemsPerPage)) {
              page++;
              pages[page] = [];
            }
            pages[page].push(dp);
          });
        } else {
          pages[1] = [...this.filteredTypes];
        }
        return pages;
      },
      set() {
        // pass
      }
    },
     tabItems() {
      const tabs = [
        {
          title: this.$t('stats.title'),
          name: 'stats',
        },
        {
          title: this.$t('traces.title'),
          name: 'traces',
        },
      ];
      if (this.userRole === 'orgadmin') {
        tabs.push({
          title: this.$t('performance.title'),
          name: 'performance',
        });
      }
      return tabs;
    },
  },

  watch: {
    sortDesc() {
      this.getStats();
    },

    orgId(value, old) {
      if (value !== old) {
        this.getStats();
      }
    },

    typeFilter: function(value, old) {
      if (value !== old) {
        this.currentPage = 1;
      }
    },

    itemsPerPage() {
      this.currentPage = 1;
    },
  },

  methods: {
    async getStats() {
      if (this.orgId > 0) {
        try {
          const { data } = await http.get(
            'logs/',
            {
              params: {
                org_id: this.orgId,
                sort_desc: this.sortDesc,
              }
            }
          );
          this.stats = data;
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .warning-message {
    padding: 16px;
    width: 650px;
    color: rgb(var(--v-theme-primary));
    border: solid 1px rgb(var(--v-theme-primary));
    border-radius: 10px;
    background-color: rgb(var(--v-theme-primary-lighten2));
  }
</style>
