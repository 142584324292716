<template>
  <div class="doc-type-config">
    <v-card style="width: 100%">
      <div class="lock-card">
        <div class="d-flex justify-space-between mb-2">
          <h4 class="label">
            <v-icon
              class="mb-1"
              size="16"
            >
              fas fa-lock
            </v-icon>
            {{ $t(`docTypes.is_locked.name`) }}
          </h4>
          <v-switch
            v-model="config.is_locked"
            class="my-0"
            style="position: absolute; right: 15px; top: 2px;"
            color="primary"
            @update:model-value="handleLock"
            inset
          />
        </div>
        <p
          class="ma-0"
          style="font-size: 0.8rem;"
        >
          {{ $t(`docTypes.lock_doctype_confirmation.intro`) }}
          <ul
            class="ma-0"
            style="font-size: 0.8rem;"
          >
            <li
              v-for="_, i in 4"
              :key="i"
              class="left-gap-lg"
            >
              {{ $t(`docTypes.lock_doctype_confirmation.list.item${i + 1}`) }}
            </li>
          </ul>
        </p>
      </div>
      <hr class="divider-line top-gap bottom-gap">
      <div class="settings-body">
        <div class="settings-left">
          <h4>
            {{ $t('docTypes.generative_section.title') }}
          </h4>
          <p style="font-size: 0.8rem;">
            {{ $t('docTypes.generative_section.text') }}
          </p>
        </div>
        <div class="settings-right">
          <h4 class="label bottom-gap-sm">
            {{ $t(`docTypes.prompt_prefix.name`) }}
          </h4>
          <p
            class="bottom-gap"
            style="font-size: 0.8rem;"
          >
            {{ $t(`docTypes.prompt_prefix.message`) }}
          </p>
          <TextEditor
            class="w-75"
            :disabled="config.is_locked"
            :text="config.prompt_prefix || ''"
            @save="(newPrompt) => savePrompt('prompt_prefix', newPrompt)"
          />
          <div
            v-if="isDatascientist"
            class="top-gap"
          >
            <h4 class="label bottom-gap-sm">
              {{ $t(`docTypes.experimental_prompt.name`) }}
            </h4>
            <p
              class="bottom-gap"
              style="font-size: 0.8rem;"
            >
              {{ $t(`docTypes.experimental_prompt.message`) }}
            </p>
            <TextEditor
              class="w-75"
              :disabled="config.is_locked"
              :text="config.experimental_prompt || config.prompt || ''"
              @save="(newPrompt) => savePrompt('experimental_prompt', newPrompt)"
            />
          </div>
        </div>
      </div>
      <hr class="divider-line top-gap bottom-gap">
      <div class="settings-body">
        <div class="settings-left">
          <h4>
            {{ $t('docTypes.processing_section.title') }}
          </h4>
          <p style="font-size: 0.8rem;">
            {{ $t('docTypes.processing_section.text') }}
          </p>
        </div>
        <div class="settings-right">
          <div
            class="lock-card bottom-gap-sm"
            style="width: 300px"
          >
            <h4 class="label">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16"
                width="16"
                viewBox="0 0 512 512"
              >
                <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
              </svg>
              {{ $t(`docTypes.warning_title`) }}
            </h4>
            <p
              class="ma-0"
              style="font-size: 0.8rem;"
            >
              {{ $t(`docTypes.warning_message`) }}
            </p>
          </div>
          <div
            v-for="key, i in processingConfig.slice(0, processingConfig.length -1)"
            :key="i"
            style="margin-top: -15px;"
          >
            <h4 class="inline-middle label mb-2">
              {{ $t(`docTypes.${key}.name`) }}
            </h4>
            <v-switch
              v-model="config[key]"
              class="inline-middle left-gap-sm"
              color="primary"
              :disabled="disableOption(key)"
              @update:model-value="updateConfig"
              inset
            />
            <p
              v-if="key !== 'post_processing_deletion'"
              style="font-size: 0.8rem; margin-top: -30px;"
            >
              {{ $t(`docTypes.${key}.message`) }}
            </p>
          </div>
          <div style="margin-top: -15px;">
            <h4 class="inline-middle label mb-2">
              {{ $t(`docTypes.qr_code.name`) }}
            </h4>
            <v-switch
              v-model="config.qr_code"
              class="inline-middle left-gap-sm"
              color="primary"
              :disabled="config.is_locked"
              @update:model-value="updateConfig"
              inset
            />
            <p
              class="bottom-gap-sm"
              style="font-size: 0.8rem; margin-top: -30px;"
            >
              {{ $t(`docTypes.qr_code.message`) }}
            </p>
            <input
              v-model="qrCodePages"
              class="input-field input-field--pages inline-middle right-gap-sm primary--text"
              placeholder="e.g. 1, 2, 5"
              :disabled="config.is_locked"
              @input="updateQrPages"
              @keydown.enter="updateConfig"
            >
          </div>
        </div>
      </div>
      <hr class="divider-line top-gap bottom-gap">
      <div class="settings-body">
        <div class="settings-left">
          <h4>
            {{ $t('docTypes.normalization_section.title') }}
          </h4>
          <p style="font-size: 0.8rem;">
            {{ $t('docTypes.normalization_section.text') }}
          </p>
        </div>
        <div class="settings-right">
          <div>
            <h4 class="label mb-2">
              {{ $t(`docTypes.languages.name`) }}
            </h4>
            <p
              class="bottom-gap-sm"
              style="font-size: 0.8rem;"
            >
              {{ $t(`docTypes.languages.message`) }}
            </p>
            <v-chip
              v-for="(language, i) in config.languages"
              :key="language"
              class="mr-1"
              style="background-color: #CCC2FF !important"
              close-icon="fas fa-times"
              variant="outlined"
              :closable="config.languages.length > 1"
              :disabled="config.is_locked"
              @click:close="removeLanguage(i)"
            >
              {{ languageMap[language] }}
            </v-chip>
            <v-chip
              v-if="config.languages.length === 0"
              class="font-italic"
              style="background-color: #CCC2FF !important"
              variant="outlined"
            >
              {{ $t(`docTypes.no_languages`) }}
            </v-chip>
            <v-btn
              v-if="!addingLanguage"
              class="left-gap-sm small-button mt-1"
              color="primary"
              :disabled="allowedLanguages.length == 0 || config.is_locked"
              @click="addingLanguage = true"
              rounded
            >
              <v-icon size="16">
                fas fa-plus
              </v-icon>
            </v-btn>
            <v-select
              v-else
              style="width: 150px; position: relative; top: 37px; margin-top: -42px;"
              class="d-inline-block ml-2"
              variant="outlined"
              color="primary"
              density="compact"
              item-title="text"
              item-value="value"
              :placeholder="$t('docTypes.choose_language')"
              :items="allowedLanguages"
              @update:model-value="addLanguage"
            />
          </div>
        </div>
      </div>
      <hr class="divider-line top-gap bottom-gap">
      <div class="settings-body">
        <div class="settings-left">
          <h4>
            {{ $t('docTypes.prevalidation_section.title') }}
          </h4>
          <p style="font-size: 0.8rem;">
            {{ $t('docTypes.prevalidation_section.text') }}
          </p>
        </div>
        <div
          class="settings-right"
          style="margin-top: -30px"
        >
          <div
            v-for="key, i in prevalidationConfig"
            :key="i"
          >
            <h4 class="inline-middle label mb-2">
              {{ $t(`docTypes.${key}.name`) }}
            </h4>
            <v-switch
              v-model="config[key]"
              class="inline-middle left-gap-sm"
              color="primary"
              :disabled="config.is_locked"
              @update:model-value="updateConfig"
              inset
            />
            <p style="font-size: 0.8rem; margin-top: -30px;">
              {{ $t(`docTypes.${key}.message`) }}
            </p>
          </div>
        </div>
      </div>
      <hr class="divider-line top-gap bottom-gap">
      <div class="settings-body">
        <div class="settings-left">
          <h4>
            {{ $t('docTypes.callback_section.title') }}
          </h4>
          <p style="font-size: 0.8rem;">
            {{ $t('docTypes.callback_section.text') }}
          </p>
        </div>
        <div class="settings-right">
          <h4 class="label mb-2">
            {{ $t(`docTypes.callback_format.name`) }}
          </h4>
          <p
            class="bottom-gap-sm"
            style="font-size: 0.8rem;"
          >
            {{ $t(`docTypes.callback_format.message`) }}
          </p>
          <div style="margin-top: 5px">
            <v-radio-group
              v-model="callbackFormat"
              :disabled="config.is_locked"
              @update:model-value="updateConfig"
              hide-details
            >
              <v-radio
                value="list"
                label="List / Array"
              />
              <pre class="code-example">
                "values": [
                  {
                    "data_point_name": "name",
                    "data_point_id": 1,
                    "value": {
                      "value": "John",
                      ...
                    }
                  },
                  {
                    "data_point_name": "age",
                    "data_point_id": 2,
                    "value": {
                      "value": "25",
                      ...
                    }
                  }
                ]
              </pre>
              <v-radio
                class="top-gap-sm"
                value="dict"
                label="Dict / Object"
              />
              <pre class="code-example">
                "values": {
                  "name": {
                    "data_point_name": "name",
                    "data_point_id": 1,
                    "value": {
                      "value": "John",
                      ...
                    }
                  },
                  "age": {
                    "data_point_name": "age",
                    "data_point_id": 2,
                    "value": {
                      "value": "25",
                      ...
                    }
                  }
                }
              </pre>
            </v-radio-group>
          </div>
        </div>
      </div>
    </v-card>
    <ConfirmDialog
      v-model="lockingDialog"
      :title="$t('docTypes.lock_doctype')"
      :message="$t('docTypes.lock_doctype_confirmation.confirm')"
      @confirm="lockDoctype(true)"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { nextTick } from 'vue';
import { http } from "@/plugins/axios";
import TextEditor from "@/components/common/elements/General/TextEditor";
import ConfirmDialog from "@/components/common/elements/Tables/ConfirmDialog";

import ui_mixin from '@/mixins/ui.js'
import { ConfigAPI } from '@/API/extract/ConfigAPI';

export default {
  name: 'DocTypeConfig',

  mixins: [ui_mixin],

  components: {
    ConfirmDialog,
    TextEditor,
  },

  data() {
    return ({
      orgConfig: {},
      lockingDialog: false,
      confirmedLock: false,
      prevalidationConfig: [
        'rules_prevalidation',
      ],
      processingConfig: [
        'post_processing_deletion',
        'image_ocr',
        'force_ocr',
        'use_deskew',
        'retry_indexing',
        'straighten_pages',
        'signature',
        'qr_code',
      ],
      editingPrompt: false,
      callbackFormat: 'list',
    });
  },

  computed: {
    isDatascientist() {
      return this.$store.getters.loggedInUser.is_datascientist;
    },

    allowedLanguages() {
      return ['en', 'fr']
        .filter(l => !this.config.languages.includes(l))
        .map(l => ({text: this.languageMap[l], value: l }));
    },

    filteredConfig() {
      return _.omit(
        this.config,
        'seamless_validation_token',
        'seamless_validation_token_duration',
        'qr_code',
        'qr_code_pages',
        'user_correction',
        'organized',
        'retry_indexing',
        'allowed_basic_users',
        'custom_reading_order',
        'straighten_pages',
        'languages',
        'is_locked',
      )
    },

    qrCodePages: {
      get() {
        return this.config.qr_code_pages &&
          this.config.qr_code_pages.map(value => value + 1).join(", ") || "";
      },

      set(value) {
        this.config.qr_code_pages = value.split(",")
          .filter(item => item)
          .map(string => {
            const numeric = string.replace(/[\p{L}\W]+/gi, '');
            return parseInt(numeric) || null;
          })
          .filter(item => item)
          .map(value => value - 1);
      }
    },
  },

  watch: {
    lockingDialog(on) {
      if (!on && !this.confirmedLock) {
        this.cancelLock();
      }
    },

    'config.callback_values_as_dict': {
      handler() {
        this.displayCallbackFormat();
      },
    },

    callbackFormat(newVal) {
      this.config.callback_values_as_dict = newVal === 'dict';
    },
  },

  async mounted() {
    this.orgConfig = await ConfigAPI.get();
    this.displayCallbackFormat();
  },

  methods: {
    displayCallbackFormat() {
      this.callbackFormat = this.config.callback_values_as_dict ? 'dict' : 'list';
    },

    updateQrCode(qrOn) {
      if (
        qrOn &&
        (!this.config.qr_code_pages || this.config.qr_code_pages.length === 0)
      ) {
        this.config.qr_code_pages = [0];
      } else if (!qrOn) {
        this.config.qr_code_pages = [];
      }
      this.updateConfig();
    },

    updateQrPages: _.debounce(
      function () {
        this.config.qr_code = this.config.qr_code_pages.length !== 0;
        this.updateConfig();
      }, 500
    ),

    async addLanguage(language) {
      this.config.languages.push(language);
      this.addingLanguage = false;
      await this.updateConfig();
    },

    removeLanguage(index) {
      this.config.languages = [...this.config.languages.filter((l, i) => i !== index)];
      this.updateConfig();
    },

    updateConfig() {
      setTimeout(() => {
        this.$emit('save');
      }, 10);
    },

    cancelLock() {
      this.$emit('updateLock', false);
      this.lockingDialog = false;
    },

    handleLock(val) {
      if (val) {
        this.lockingDialog = true;
      } else {
        this.lockDoctype(val);
      }
      this.$emit('updateLock', val);
    },

    async lockDoctype(is_locked) {
      try {
        this.$store.commit('setLoadingScreen', true);
        await http.patch(`system_2/document_type/${this.$route.params.id}/lock/`, null, {
          params: {
            is_locked,
          }
        });
        this.confirmedLock = is_locked;
        this.lockingDialog = false;
        nextTick(() => {
          this.confirmedLock = false;
        });
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    savePrompt(key, value) {
      this.config[key] = value;
      this.updateConfig();
    },

    disableOption(option) {
      if (this.config.is_locked) {
        return true;
      }
      if (option === 'use_deskew') {
        if (!this.config.force_ocr) {
          return true;
        }
      }
      if (option === 'straighten_pages') {
        if ((this.orgConfig.ocr_model !== 'GOOGLE' && this.orgConfig.ocr_model !== 'AZURE') || !this.config.force_ocr) {
          return true;
        }
      }
      return false;
    },
  },

  props: {
    config: {
      type: Object,
      required: true,
    },
  },

  emits: ['refresh', 'save', 'updateLock'],
}
</script>

<style lang="scss" scoped>
.doc-type-config {
  display: flex;
  .form-label {
    font-size: 0.8rem;
  }

  .settings-body {
    display: flex;
    .settings-left {
      width: 35%;
      padding-right: 20px;
    }
    .settings-right {
      width: 65%;
    }
  }

  .lock-card {
    width: calc(35% + 5px);
    padding: 15px;
    border: 1px solid rgb(var(--v-theme-primary));
    border-radius: 5px;
    background-color: #502BFF33;
    position: relative;
  }


.small-button {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

  .input-field {
    display: inline-block;
    background-color: #ffffff00;
    padding: 0 !important;
    border: none;
    border-bottom: 1px solid rgb(var(--v-theme-primary));

    &:focus {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }

    &:focus-visible {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }

    &--hours {
      width: 70px;
    }

    &--pages {
      width: 160px;
    }
  }
}

.code-example {
  font-family: 'Courier New', Courier, monospace;
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 4px;
  font-size: 0.8rem;
  margin: 0.5rem 0;
  overflow-x: auto;
  tab-size: 2;
}
</style>
