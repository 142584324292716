<template>
  <div class="extractors-view">
    <TableActions
      :type="tableActionsType"
      :number-of-selected="selected.length + selectedGroups.length"
      :lock-actions="lockActions"
      :used-models="usedModels"
      @edit-click="handleEditClick"
      @delete-click="handleDeleteClick"
      @filter-change="(filter) => {trimmedFilter = filter; handleFilter()}"
      @filter-enter="handleEnter"
      @refresh-used-models="getUsedModels"
    >
      <template #actions>
        <TableAction
          v-if="selected.length + selectedGroups.length === 1"
          class="mr-2"
          prepend-icon="fas fa-clone"
          :label="$t('dataPoints.generative.clone_generative_message')"
          :disabled="lockActions"
          @click="setGenerativeCopy(selected[0] || selectedGroups[0])"
        />
      </template>
    </TableActions>
    <DataPointsTable
      ref="DPTable"
      :all-items="dataPoints"
      :filtered-items="filtered"
      :loading="loading"
      :disabled="lockActions"
      @set-items="items => $emit('updateDataPoints', items)"
      @get-items="sortDesc => $emit('getDataPoints', sortDesc)"
      @save-name="$emit('saveDPName')"
    />
    <LabelGroupsTable
      ref="GroupTable"
      :all-items="labelGroups"
      :filtered-items="filteredLabelGroups"
      :loading="loading"
      :disabled="lockActions"
      @set-items="items => $emit('updateLabelGroups', items)"
      @get-items="sortDesc => $emit('getLabelGroups', sortDesc)"
      @save-name="$emit('saveGroupName')"
    />
    <DataPointNameDialog
      v-model="showNameDialog"
      :initial-name="generativeCopy?.name"
      @close="showNameDialog = false; generativeCopy = null"
      @save="(name) => handleCopyGenerative(generativeCopy, name)"
    />
    <ConfirmDialog
      v-model="deleteDialog"
      :title="deleteDialogTitle"
      :message="deleteDialogMessage"
      @confirm="deleteDialogConfirmOptions"
    />
  </div>
</template>

<script>
import ConfirmDialog from "@/components/common/elements/Tables/ConfirmDialog";
import TableActions from '@/components/common/elements/Tables/TableActions';
import DataPointsTable from '@/components/extract/elements/DataPoints/DataPointsTable';
import LabelGroupsTable from '@/components/extract/elements/LabelGroups/LabelGroupsTable';
import DataPointNameDialog from '@/components/extract/elements/DataPoints/DataPointNameDialog';
import TableAction from  "@/components/common/elements/Tables/TableAction";

import model_mixin from '@/mixins/model.js';
import { http } from '@/plugins/axios';
import { DataPointAPI } from '@/API/extract/DataPointAPI';
import { copyGenerative } from '@/utils/ExtractorUtils';

export default {
  name: 'ExtractorsView',

  mixins: [
    model_mixin,
  ],

  components: {
    ConfirmDialog,
    DataPointsTable,
    LabelGroupsTable,
    TableActions,
    DataPointNameDialog,
    TableAction,
  },

  data() {
    return ({
      trimmedFilter: '',
      itemsPerPage: 20,
      currentPage: 1,
      deleteDialog: false,
      forceCompute: Math.random(),
      usedModels: [],
      showNameDialog: false,
      generativeCopy: null,
    });
  },

  computed: {
    tableActionsType() {
      if (this.selected.length > 0 && this.selectedGroups.length === 0) {
        return 'dataPoints';
      } else if (this.selected.length === 0 && this.selectedGroups.length > 0) {
        return 'labelGroups';
      } else if (this.selected.length > 0 || this.selectedGroups.length > 0) {
        return 'extractors';
      }
      return 'DPsAndGroups';
    },

    loading() {
      return this.docTypeLoading;
    },

    user() {
      return this.$store.getters.loggedInUser;
    },

    filtered: {
      cache: false,
      get: function() {
        if (this.dataPoints && this.dataPoints.length > 0) {
          return this.dataPoints.filter(dp => dp.name.toLowerCase().includes(this.trimmedFilter));
        }
        return [];
      }
    },

    filteredLabelGroups: {
      cache: false,
      get: function() {
        if (this.labelGroups && this.labelGroups.length > 0) {
          return this.labelGroups.filter(label => label.name.toLowerCase().includes(this.trimmedFilter));
        }
        return [];
      }
    },

    selected: {
      cache: false,
      get() {
        this.forceCompute;
        if (this.filtered.length > 0) {
          return this.filtered.filter(dp => dp.selected);
        }
        return [];
      },
      set() {
        return this.selected;
      }
    },

    selectedGroups: {
      cache: false,
      get() {
        this.forceCompute;
        if (this.filteredLabelGroups.length > 0) {
          return this.filteredLabelGroups.filter(group => group.selected);
        }
        return [];
      },
      set() {
        return this.selectedGroups;
      }
    },

    deleteDialogTitle() {
      if (this.tableActionsType === 'dataPoints') {
        return this.$t('datatable.deleteDP');
      }
      if (this.tableActionsType === 'labelGroups') {
        return this.$t('datatable.deleteGroup');
      }
      if (this.tableActionsType === 'extractors') {
        return this.$t('datatable.deleteExtractors');
      }
      return '';
    },

    deleteDialogMessage() {
      if (this.tableActionsType === 'dataPoints') {
        return this.$t('datatable.deleteConfirmationDP');
      }
      if (this.tableActionsType === 'labelGroups') {
        return this.$t('datatable.deleteConfirmationGroup');
      }
      if (this.tableActionsType === 'extractors') {
        return this.$t('datatable.deleteConfirmationExtractors');
      }
      return '';
    },

    deleteDialogConfirmOptions() {
      if (this.tableActionsType === 'dataPoints') return  this.deleteDP;
      if (this.tableActionsType === 'labelGroups') return this.deleteGroup;
      if (this.tableActionsType === 'extractors') return this.deleteExtractors;
      return null;
    },
  },

  async mounted() {
    await this.getUsedModels();
  },

  methods: {
    setGenerativeCopy(extractor) {
      this.generativeCopy = {
        ...extractor,
        type: this.selected.find((dp) => dp.id === extractor.id) && 'single' || 'group',
      };
      this.showNameDialog = true;
    },

    async handleCopyGenerative(extractor, name) {
      try {
        this.$store.commit('setLoadingScreen', true);
        const agentId = extractor.document_type_id || this.$route.params.id;
        await copyGenerative(extractor, name, agentId);
        this.$store.commit('setSuccessMessage', this.$t('dataPoints.generative.cloned'));
        this.$store.commit('setSuccessSnackbar', true);
        this.$store.commit('setLoadingScreen', false);
        this.showNameDialog = false;
        this.generativeCopy = null;
        this.$nextTick(() => {
          const event = extractor.labels && 'getLabelGroups' || 'getDataPoints';
          this.$emit(event);
        });
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    async deleteDP() {
      const length = this.selected.length;
      for (let i = 0; i < length; i++) {
        try {
          await http.delete(`system_2/data_point/${this.selected[i].id}`);
        } catch (error) {
          return
        }
      }
      this.finishDeletion();
    },

    finishDeletion() {
      const deletedIds = this.selected.map(item => item.id);
      const dpList = this.dataPoints.filter(dp => !deletedIds.includes(dp.id));
      this.$emit('updateDataPoints', dpList);
      this.deleteDialog = false;
      this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.deleted'));
      this.$store.commit('setSuccessSnackbar', true);
    },

    async deleteGroup() {
      const length = this.selectedGroups.length;
      for (let i = 0; i < length; i++) {
        try {
          if (this.selectedGroups[i].category === 'generative') {
            await DataPointAPI.delete(this.selectedGroups[i].id);
          } else {
            await http.delete(`system_2/extraction_groups/${this.selectedGroups[i].id}`);
          }
        } catch (error) {
          return
        }
      }
      this.finishGroupDeletion();
    },

    finishGroupDeletion() {
      const deletedIds = this.selectedGroups.map(item => item.id);
      const groups = this.labelGroups.filter(group => !deletedIds.includes(group.id));
      this.$emit('updateLabelGroups', groups);
      this.deleteDialog = false;
      this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.deleted'));
      this.$store.commit('setSuccessSnackbar', true);
    },

    async deleteExtractors() {
      const promises = [];
      if (this.selected.length > 0) promises.push(this.deleteDP());
      if (this.selectedGroups.length > 0) promises.push(this.deleteGroup());
      await Promise.all(promises);
    },

    handleEnter() {
      let item;
      let tableName;
      if (this.filtered.length === 1 && this.filteredLabelGroups.length === 0) {
        item = this.filtered[0];
        tableName = 'DPTable';
      } else if (this.filteredLabelGroups.length === 1 && this.filtered.length === 0) {
        item = this.filteredLabelGroups[0];
        tableName = 'GroupTable';
      }
      if (item) {
        const table = this.$refs[tableName];
        if (table) {
          table.handleEditButton(this.$route.params.id);
        }
      }
    },

    handleEditClick() {
      if (this.tableActionsType === 'dataPoints') {
        this.handleEdit();
      } else if (this.tableActionsType === 'labelGroups') {
        this.handleEditGroups();
      }
    },

    handleEdit() {
      this.edited = this.selected[0];
      this.$refs.DPTable.$refs[`name_${this.edited.id}`][0].startRenaming();
    },

    handleEditGroups() {
      this.edited = this.selectedGroups[0];
      this.$refs.GroupTable.$refs[`name_${this.edited.id}`][0].startRenaming();
    },

    handleFilter() {
      if (this.trimmedFilter !== '') {
        this.currentPage = 1;
      }
    },

    prevPage() {
      if (!this.isFirstPage) {
        this.currentPage--;
      }
    },

    nextPage() {
      if (!this.isLastPage) {
        this.currentPage++;
      }
    },

    handleDeleteClick() {
      this.deleteDialog = true;
    },
  },

  props: {
    dataPoints: {
      required: true,
      type: Array,
    },

    labelGroups: {
      required: true,
      type: Array,
    },

    docTypeLoading: {
      required: true,
      type: Boolean,
    },

    lockActions: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'updateDataPoints',
    'getDataPoints',
    'saveDPName',
    'updateLabelGroups',
    'getLabelGroups',
    'saveGroupName',
  ],
}
</script>
<style lang="scss">
.bottom-nav {
  font-size: 12px;
  width: 350px;
  float: right;
}

.table-row {
  padding: 1rem 1.5rem;
  margin: 0 !important;
  width: 100%;

  &:not(&:last-of-type) {
    border-bottom: 1px solid #eee;
  }

  &__header {
    font-size: 14px;
    border-bottom: 1px solid rgb(var(--v-theme-primary));
  }
}
.disabled {
  color: #999 !important;
}
</style>
